import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

export default async function checkPublicView(to: RouteLocationNormalized): Promise<RouteLocationRaw | void> {
  const applicationStore = useApplicationStore();
  if (applicationStore.activeUser) return;

  try {
    const view = await client.getViewById({ viewId: to.params['id'] as string });
    if (view?.isPublic) return;
  } catch {}

  return { name: PageNames.Login, query: { continueUrl: to.path } };
}
