import { Ref } from 'vue';

export function setTimeoutNotification(currentNotification: Ref, timeoutMs?: number) {
  if (timeoutMs == undefined) return;
  setTimeout(() => (currentNotification.value = undefined), timeoutMs);
}

export function getCssClass(state: string) {
  if (state === 'error') return 'open notification-error' as const;
  if (state === 'success') return 'open notification-success' as const;
  return 'notification' as const;
}
