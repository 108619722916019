import * as client from '@gabrielcam/api-client';

const EntitlementsDescriptions: Record<client.Entitlements, string> = {
  [client.Entitlements.CREATE_ORGANISATION]: 'Permission to create a new organisation',
  [client.Entitlements.READ_ORGANISATION]: 'Permission to view organisation details',
  [client.Entitlements.UPDATE_ORGANISATION]: 'Permission to update organisation details',
  [client.Entitlements.DELETE_ORGANISATION]: 'Permission to delete an organisation',
  [client.Entitlements.CREATE_CLIENT]: 'Permission to create a new client',
  [client.Entitlements.READ_CLIENT]: 'Permission to view client details',
  [client.Entitlements.UPDATE_CLIENT]: 'Permission to update client details',
  [client.Entitlements.DELETE_CLIENT]: 'Permission to delete a client',
  [client.Entitlements.CREATE_PROJECT]: 'Permission to create a new project',
  [client.Entitlements.READ_PROJECT]: 'Permission to view project details',
  [client.Entitlements.UPDATE_PROJECT]: 'Permission to update project details',
  [client.Entitlements.DELETE_PROJECT]: 'Permission to delete a project',
  [client.Entitlements.CREATE_VIEW]: 'Permission to create a new view',
  [client.Entitlements.READ_VIEW]: 'Permission to view view details',
  [client.Entitlements.UPDATE_VIEW]: 'Permission to update view details',
  [client.Entitlements.DELETE_VIEW]: 'Permission to delete a view',
  [client.Entitlements.CREATE_VIEWER]: 'Permission to create a new viewer',
  [client.Entitlements.READ_VIEWER]: 'Permission to view viewer details',
  [client.Entitlements.UPDATE_VIEWER]: 'Permission to update viewer details',
  [client.Entitlements.DELETE_VIEWER]: 'Permission to delete a viewer',
  [client.Entitlements.CREATE_CAMERA]: 'Permission to create a new camera',
  [client.Entitlements.READ_CAMERA]: 'Permission to view camera details',
  [client.Entitlements.UPDATE_CAMERA]: 'Permission to update camera details',
  [client.Entitlements.DELETE_CAMERA]: 'Permission to delete a camera',
  [client.Entitlements.REGISTER_CAMERA]: 'Permission to register a new camera',
  [client.Entitlements.CREATE_USER]: 'Permission to create a new user',
  [client.Entitlements.READ_USER]: 'Permission to view user details',
  [client.Entitlements.UPDATE_USER]: 'Permission to update user details',
  [client.Entitlements.DELETE_USER]: 'Permission to delete a user',
  [client.Entitlements.GRANT_ROLE]: 'Permission to grant roles to a user',
  [client.Entitlements.REVOKE_ROLE]: 'Permission to revoke roles from a user',
  [client.Entitlements.CREATE_SEQUENCE]: 'Permission to create a new sequence',
  [client.Entitlements.READ_SEQUENCE]: 'Permission to view sequence details',
  [client.Entitlements.UPDATE_SEQUENCE]: 'Permission to update sequence details',
  [client.Entitlements.DELETE_SEQUENCE]: 'Permission to delete a sequence',
  [client.Entitlements.CREATE_BATCH_DOWNLOAD]: 'Permission to create a new batch download',
  [client.Entitlements.READ_BATCH_DOWNLOAD]: 'Permission to view batch download details',
  [client.Entitlements.UPDATE_BATCH_DOWNLOAD]: 'Permission to update batch download details',
  [client.Entitlements.DELETE_BATCH_DOWNLOAD]: 'Permission to delete a batch download',
  [client.Entitlements.CREATE_SYSTEM]: 'Permission to create a new system',
  [client.Entitlements.READ_SYSTEM]: 'Permission to view system details',
  [client.Entitlements.UPDATE_SYSTEM]: 'Permission to update system details',
  [client.Entitlements.DELETE_SYSTEM]: 'Permission to delete a system',
  [client.Entitlements.CREATE_OPERATION]: 'Permission to create a new operation',
  [client.Entitlements.READ_OPERATION]: 'Permission to view operation details',
  [client.Entitlements.UPDATE_OPERATION]: 'Permission to update operation details',
  [client.Entitlements.DELETE_OPERATION]: 'Permission to delete an operation',
  [client.Entitlements.CREATE_LOG]: 'Permission to create a new log',
  [client.Entitlements.DELETE_IMAGE]: 'Permission to delete an image',
};

type GroupedEntitlement = {
  entitlements: client.Entitlements[];
  descriptions: string[];
};

export type GroupedEntitlements = {
  [entity: string]: GroupedEntitlement;
};

export const groupEntitlements = (): GroupedEntitlements => {
  const grouped: GroupedEntitlements = {};

  Object.keys(EntitlementsDescriptions).forEach((key) => {
    // Extract entity by splitting camelCase at the first uppercase letter
    const entityMatch = key.match(/[A-Z][a-z]+/g);
    const entity = entityMatch ? entityMatch.join('') : '';

    if (entity) {
      if (!grouped[entity]) {
        grouped[entity] = { entitlements: [], descriptions: [] };
      }

      grouped[entity].entitlements.push(key as client.Entitlements);
      grouped[entity].descriptions.push(EntitlementsDescriptions[key as client.Entitlements]);
    }
  });

  // Create a sorted object based on entity names (keys)
  return Object.keys(grouped)
    .sort((a, b) => a.localeCompare(b)) // Sort entities alphabetically
    .reduce((acc, key) => {
      // Use a non-null assertion (!) to tell TypeScript that grouped[key] is never undefined
      acc[key] = grouped[key]!;
      return acc;
    }, {} as GroupedEntitlements);
};
