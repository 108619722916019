<script setup lang="ts">
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';

const props = defineProps<{
  downloadURL: string | undefined;
}>();

const applicationStore = useApplicationStore();
const canRead = applicationStore.canUser(Entitlements.READ_BATCH_DOWNLOAD, applicationStore.activeOrganisation!);
</script>

<template>
  <div v-if="canRead"
       class="collection-item-actions">
    <ul>
      <li v-if="props.downloadURL && canRead">
        <a :href="props.downloadURL"
           class="action-download"
           data-title="Download">Download</a>
      </li>
    </ul>
  </div>
</template>
