<script setup lang="ts">
import { reactive } from 'vue';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/directory/models/Table';
import TLSTable from '@components/directory/Table.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

const enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const applicationStore = useApplicationStore();

async function getProjectList(): Promise<client.Project[]> {
  const org = applicationStore.activeOrganisation;
  const res = await client.listProjects({ organisation: org!.id });
  return res.data;
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    showActions: false,
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    showActions: false,
    headerClasses: 'status',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
  { title: BreadcrumbTitles.Projects, active: true },
];
</script>

<template>
  <SubHeader heading="Projects"
             level="2">
    <template #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.ProjectCreate }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusCircleIcon"
                       :icon-style="IconStyle.Solid">
        Add Project
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div>
          <TLSTable :retrieve-data="getProjectList"
                    :columns="columns"
                    :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No projects found"
                          strap-line="Get started by naming your first project"
                          :button-variant="ButtonVariant.Dark"
                          button-text="New Project"
                          :icon-name="IconName.ClipboardDocumentListIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.ProjectCreate }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Actions">
                <div class="d-flex justify-between gap-20">
                  <ButtonComponent :to="{ name: PageNames.ProjectEdit, params: { id: row.id } }"
                                   :icon-name="IconName.AdjustmentsHorizontalIcon"
                                   :icon-style="IconStyle.Outline"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Edit
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TLSTable>
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
