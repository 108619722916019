<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import * as client from '@gabrielcam/api-client';
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BadgeVariant, ButtonSize, ButtonVariant, PageNames, UserStatus } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { UserViewModel } from '@viewModels/userViewModel';

import BadgeComponent from '@components/BadgeComponent.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import { TableColumn } from '@components/table/models/Table';
import TLSTable from '@components/table/Table.vue';
import EmptyState from '@layouts/EmptyState.vue';

const applicationStore = useApplicationStore();
const showEdit = applicationStore.canUser(Entitlements.UPDATE_USER, applicationStore.activeOrganisation!);
const showNew = applicationStore.canUser(Entitlements.CREATE_USER, applicationStore.activeOrganisation!);
const showOrg = applicationStore.canUser(Entitlements.CREATE_ORGANISATION, applicationStore.activeOrganisation!);
const users = ref<UserViewModel[]>([]);
const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(true);
const router = useRouter();

enum TableHeaders {
  Name = 'Name',
  Email = 'Email',
  Roles = 'Roles',
  Status = 'Status',
  Actions = 'Actions',
}

onMounted(async () => {
  isLoading.value = true;
  users.value = await getUsersList();
  isLoading.value = false;
});

async function resendInvite(user: UserViewModel): Promise<void> {
  isSubmitting.value = true;
  try {
    await client.createInvitation({
      requestBody: {
        email: user.email,
        displayName: user.displayName,
        roles: user.roles,
      },
    });
    applicationStore.publishSuccessNotification({
      text: 'Successfully invited user.',
      autoCloseMs: 3000,
    });
    router.push({ name: PageNames.Users });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }
}

async function getUsersList(): Promise<UserViewModel[]> {
  const org = applicationStore.activeOrganisation;
  const res = await client.listUsers({ organisation: org!.id, type: client.UserTypes.PERSON });
  const roles = (await client.listRoles()).data;
  return res.data.map((user) => {
    const userRoleNames = user.roles.map((role) => roles.find((r) => r.id == role.id)?.shortName);
    const uniqueRolenames = userRoleNames.filter((value, index, array) => array.indexOf(value) === index);
    const userRoles = uniqueRolenames.join(', ');
    return {
      id: user.id,
      status: user.status,
      email: user.email,
      displayName: user.displayName,
      roles: user.roles,
      rolesDisplay: userRoles,
    };
  });
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'displayName',
    width: '1%',
  },
  {
    label: TableHeaders.Email,
    field: 'email',
    headerClasses: 'text--white-space-nowrap',
    width: '45%',
  },
  {
    label: TableHeaders.Roles,
    field: 'rolesDisplay',
    headerClasses: 'text--white-space-nowrap',
    width: '45%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'status',
    width: '1%',
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    headerClasses: '',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
</script>

<template>
  <SubHeader heading="Users"
             level="2">
    <template v-if="showNew"
              #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.UserInvitation }"
                       :variant="ButtonVariant.Dark">
        Invite User
      </ButtonComponent>
    </template>
  </SubHeader>

  <ContainerCard>
    <div class="main-wrapper">
      <TLSTable :columns="table.columns"
                :data="users"
                :is-loading="isLoading"
                :sortable="table.sortable"
                :total="table.totalRecordCount">
        <template #table-empty>
          <EmptyState heading-text="No users found"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Outline" />
        </template>

        <template #cell="{ row, column }">
          <div v-if="column.label === TableHeaders.Status"
               class="d-flex justify-center">
            <BadgeComponent v-if="row.status === UserStatus.Active"
                            :is-pill="true"
                            :variant="BadgeVariant.Success">
              Active
            </BadgeComponent>
            <BadgeComponent v-if="row.status === UserStatus.Invited"
                            :is-pill="true"
                            :variant="BadgeVariant.Warning">
              Invited
            </BadgeComponent>
            <BadgeComponent v-if="row.status === UserStatus.Accepted"
                            :is-pill="true"
                            :variant="BadgeVariant.Info">
              Accepted
            </BadgeComponent>
          </div>

          <div v-if="column.label === TableHeaders.Actions"
               class="d-flex gap-20">
            <ButtonComponent v-if="showEdit"
                             :icon-name="IconName.WrenchIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.UserEdit, params: { id: row.id } })">
              Edit
            </ButtonComponent>
            <ButtonComponent v-if="showOrg"
                             :icon-name="IconName.CogIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.UserRoles, params: { id: row.id } })">
              Roles
            </ButtonComponent>
            <ButtonComponent v-if="showNew && row.status === UserStatus.Invited"
                             :icon-name="IconName.EnvelopeOpenIcon"
                             :icon-style="IconStyle.Outline"
                             :icon-position="IconPosition.Left"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Info"
                             @click="resendInvite(row)">
              Resend Invite
            </ButtonComponent>
          </div>
        </template>
      </TLSTable>
    </div>
  </ContainerCard>
</template>
