import type { Router } from 'vue-router';

const EXCLUDE_PATHS = ['/login'];
export const getContinueUrl = (router: Router): string | undefined => {
  const continueUrl = router.currentRoute.value.query['continueUrl'];
  if (continueUrl) {
    try {
      return new URL(String(continueUrl)).pathname;
    } catch (err) {
      return String(continueUrl);
    }
  }
  const currentPath = router.currentRoute.value.path;
  return EXCLUDE_PATHS.includes(currentPath) ? '/views' : currentPath;
};
