<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApplicationStore } from '@stores/application';

import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
</script>

<template>
  <div v-if="adminMode">
    <SubHeader heading="Admin"
               level="2" />

    <ContainerCard>
      <div>Admin Page</div>
    </ContainerCard>
  </div>

  <div v-else>
    <p>You do not have access to this page.</p>
  </div>
</template>

<style scoped lang="scss"></style>
