<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useApplicationStore } from '@stores/application';

import Footer from '@components/layout/Footer.vue';
import Navbar from '@components/layout/Navbar.vue';
import Loading from '@components/Loading.vue';
import MainContentLayout from '@layouts/MainContentLayout.vue';
import SidebarLayout from '@layouts/SidebarLayout.vue';

const applicationStore = useApplicationStore();
const { activeOrganisation } = storeToRefs(applicationStore);

const route = useRoute();
const pageName = computed(() => route.name as string);
</script>

<template>
  <Loading v-if="!applicationStore.applicationReady" 
           :is-absolute-positioned="true" />

  <div v-else
       id="appContainer"
       :style="{
         '--primary-color': activeOrganisation?.settings?.primaryColour,
         '--secondary-color': activeOrganisation?.settings?.secondaryColour,
         '--logo': `url(${activeOrganisation?.settings?.logoBase64 ?? applicationStore.defaultTheme.logo})`,
       }"
       class="app-container">
    <SidebarLayout>
      <template #navbar>
        <Navbar />
      </template>
    </SidebarLayout>

    <MainContentLayout :page-name="pageName">
      <template #router-view>
        <router-view />
      </template>

      <template #footer>
        <Footer />
      </template>
    </MainContentLayout>
  </div>
</template>

<style lang="scss">
@import '../scss/style.scss';
</style>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
}
</style>
