<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

const someRefName = ref<HTMLElement>();
const props = defineProps({
  selected: {
    type: Boolean,
    required: false,
  },
});

watch(
  () => props.selected,
  () => {
    scrollToCard();
  }
);
onMounted(() => {
  scrollToCard();
});

function scrollToCard(): void {
  if (!props.selected || !someRefName.value) return;

  someRefName.value.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
}
</script>

<template>
  <div ref="someRefName"
       class="collection-item"
       :class="{ active: props.selected }">
    <slot name="card-contents" />
    <slot name="card-actions" />
  </div>
</template>
