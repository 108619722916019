import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export function useCurrentRoute() {
  const route = useRoute();
  const currentRoutePath = ref(route.path);
  const currentRouteName = ref(route.name);
  const currentRouteMetaTitle = ref(route.meta['title'] as string | '');

  const updateRoute = () => {
    currentRoutePath.value = route.path as string;
    currentRouteName.value = route.name as string;
    currentRouteMetaTitle.value = route.meta['title'] as string | '';
  };

  watch(route, updateRoute);

  return {
    currentRoutePath,
    currentRouteName,
    currentRouteMetaTitle,
  };
}
