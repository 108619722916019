<script setup lang="ts">
import { onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useVideoStore, Video } from '@stores/video';
import { ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import VideoCard from '@components/cards/videoCard/VideoCard.vue';
import Directory from '@components/directory/Directory.vue';
import ModalComponent from '@components/ModalComponent.vue';
import VideoDirectoryFilter from '@components/video/VideoDirectoryFilter.vue';
import VideoDirectoryPagination from '@components/video/VideoDirectoryPagination.vue';
import VideoPlayer from '@components/video/VideoPlayer.vue';

const videoStore = useVideoStore();
const route = useRoute();
const { videos } = storeToRefs(videoStore);

const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const videoPlayerVisible = ref(false);
const videoPlayerUrl = ref<string>('');
const viewId = ref<string>();
const sortBy = ref<string | undefined>();

watch(
  () => route.params['id'],
  async (viewId) => {
    await videoStore.getVideos(viewId as string, sortBy.value);
  }
);
async function updateSort(newSortBy: string): Promise<void> {
  sortBy.value = newSortBy;
  await videoStore.getVideos(viewId.value, newSortBy);
}

onMounted(async () => {
  viewId.value = route.params['id'] as string | undefined;

  await videoStore.getVideos(viewId.value);
});

function closeVideoPlayerModal(): void {
  videoPlayerVisible.value = false;
}
function openVideoPlayer(video: Video): void {
  videoPlayerVisible.value = true;
  videoPlayerUrl.value = video.url ?? '';
}
</script>

<template>
  <ContainerCard>
    <VideoDirectoryFilter @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                          @sort-view-by="(value: string) => updateSort(value)" />

    <Directory :object-collection-reference="videos.data">
      <!-- We apply the type of card we want to add -->
      <template #default="scope">
        <!-- @vue-ignore -->
        <VideoCard :resource="scope.resource"
                   @on-click="openVideoPlayer(scope.resource)" />
      </template>
    </Directory>
    <VideoDirectoryPagination />
  </ContainerCard>

  <!-- Video Player Modal -->
  <ModalComponent :visible="videoPlayerVisible"
                  heading-title="Watch Video"
                  @on-close="closeVideoPlayerModal">
    <template #modal-content>
      <VideoPlayer :video-src="videoPlayerUrl" />
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeVideoPlayerModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
