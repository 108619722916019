<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';

// Interface
interface FeatureFlagProps {
  name: string;
}

// Props
const props = defineProps<FeatureFlagProps>();
const isFeatureEnabled = ref(false);

// Check the feature flag from localStorage
const checkFeatureFlag = (): void => {
  let featureStatus = localStorage.getItem(props.name);

  // Initialize the feature flag in localStorage if it doesn't exist
  if (!featureStatus) {
    localStorage.setItem(props.name, 'false');
    featureStatus = 'false';
  }

  isFeatureEnabled.value = featureStatus === 'true';
};

// Lifecycles
onMounted(() => {
  checkFeatureFlag();
  window.addEventListener('storage', checkFeatureFlag);
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', checkFeatureFlag);
});
</script>

<template>
  <div v-if="isFeatureEnabled">
    <slot />
  </div>
</template>
