<template>
  <section class="card-container">
    <slot />
  </section>
</template>

<style scoped lang="scss">
@use '@/scss/variables' as *;

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: clamp($gap-desktop, 4vw, $gap-default * 2);
}
</style>
