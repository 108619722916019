<script setup lang="ts">
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { PageNames } from '@viewModels/enums';

import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/directory/models/Table';
import TLSTable from '@components/directory/Table.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';

const router = useRouter();

async function getOrganisationList(): Promise<client.Organisation[]> {
  return (await client.listOrganisations()).data;
}

const columns: TableColumn[] = [
  {
    label: 'Name',
    field: 'name',
    showActions: false,
  },
];

columns.push({
  label: 'Actions',
  field: 'id',
  // deleteFunc: showDeleteConfirmModal,
  toSettingsFunc: (row: client.Camera) => {
    router.push({ name: PageNames.OrganisationSettings, params: { id: row.id } });
  },
  showActions: true,
  headerClasses: 'table-actions',
  width: '1%',
});

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});
</script>
<template>
  <SubHeader heading="Organisations"
             level="2" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div class="main-wrapper">
          <TLSTable :retrieve-data="getOrganisationList"
                    :columns="table.columns"
                    :total="table.totalRecordCount"
                    :sortable="table.sortable" />
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
