<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import dayjs from 'dayjs';
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

import { generateMissingDateRanges } from '@utils/generateMissingDates';

const props = defineProps<{
  availableDates: string[];
  modelValue: string;
  selectedDate: string;
}>();

const date = ref(props.selectedDate);
const height = ref(601);
const calendar = ref<InstanceType<typeof DatePicker>>();

watch(
  () => props.selectedDate,
  async () => {
    date.value = props.selectedDate;
    await calendar.value?.move(date.value);
  }
);

const emit = defineEmits<{
  (e: 'dateSelected', value: string): void;
  (e: 'update:modelValue', value: string): void;
}>();

const disabledDates = computed(() => {
  return generateMissingDateRanges(props.availableDates);
});

function onUpdateDate(e: string | Date | null | undefined): void {
  const date = dayjs(e); // use dayjs to parse the date string

  if (date.isValid()) {
    const formattedDate = date.format('YYYY-MM-DD');
    emit('update:modelValue', formattedDate);
  } else {
    console.warn('Invalid date value:', e);
  }
}
</script>

<template>
  <DatePicker ref="calendar"
              v-model="date"
              expanded
              is-dark
              transparent
              borderless
              color="red"
              :is-inline="height > 600"
              :disabled-dates="disabledDates"
              mode="date"
              nav-visibility="none"
              :min-date="props.availableDates[0]"
              :max-date="props.availableDates.at(-1)"
              @update:model-value="onUpdateDate" />
</template>
