<script setup lang="ts">
import { PropType, ref, toRefs } from 'vue';

import * as client from '@gabrielcam/api-client';

import ViewCardContents from '@components/cards/shared/ViewCardContents.vue';
import ViewCardWrapper from '@components/cards/shared/ViewCardWrapper.vue';

import BatchImageCardActions from './BatchImageCardActions.vue';

const props = defineProps({
  resource: {
    type: Object as PropType<client.BatchZip>,
    required: true,
  },
});
const overlay = ref<string>('');

defineEmits<{
  (e: 'download'): void;
  (e: 'onClick'): void;
}>();

const { resource } = toRefs(props);
</script>

<template>
  <ViewCardWrapper v-if="resource">
    <template #card-contents>
      <ViewCardContents :title="props.resource.fileName"
                        :link="props.resource.signedDownloadUrl"
                        :overlay="overlay"
                        @on-click="$emit('onClick')" />
    </template>

    <template #card-actions>
      <BatchImageCardActions :image-id="resource.id"
                             :download-u-r-l="resource.signedDownloadUrl" />
    </template>
  </ViewCardWrapper>
</template>
