<script setup lang="ts">
import { PropType } from 'vue';

import { useField } from 'vee-validate';

import * as client from '@gabrielcam/api-client';

const props = defineProps({
  camera: {
    type: Object as PropType<client.Camera>,
    required: true,
  },
});

const { value: serialNumber } = useField<string>('serialNumber', 'serialNumber');
const { value: wpImei } = useField<string>('wpImei', 'wpImei');
const { value: wpSerialNumber } = useField<string>('wpSerialNumber', 'wpSerialNumber');

serialNumber.value = props.camera.serialNumber || '';
wpImei.value = (props.camera.meta!['wpImei'] as string) || '';
wpSerialNumber.value = (props.camera.meta!['wpSerialNumber'] as string) || '';
</script>
<template>
  <div class="field-group">
    <div class="field-group-info">
      <h3>Camera Device Information</h3>
      <p>Unique device identifier and system data.</p>
    </div>
    <div class="fields">
      <div class="row-half">
        <div class="field">
          <label id="input-vname"
                 for="serialNumber">Device Id</label>
          <input id="serialNumber"
                 v-model="serialNumber"
                 disabled
                 type="text">
        </div>
      </div>
      <div class="row-half">
        <div class="field">
          <label id="input-vname"
                 for="wpSerialNumber">Serial Number</label>
          <input id="wpSerialNumber"
                 v-model="wpSerialNumber"
                 disabled
                 type="text">
        </div>
        <div class="field">
          <label id="input-vname"
                 for="wpImei">IMEI</label>
          <input id="wpImei"
                 v-model="wpImei"
                 disabled
                 type="text">
        </div>
      </div>
    </div>
  </div>
</template>
