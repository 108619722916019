<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';
import { GetCameraByIdStatLatestData } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore, View } from '@stores/view';
import { AlertVariant, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import AlertBanner from '@components/AlertBanner.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';

interface ViewUpdateForm {
  name: string;
  camera?: string;
  client: string;
  project: string;
  longitude: number;
  latitude: number;
  isPublic: boolean;

  bucket?: string;
  prefix?: string;
}

const schema = yup.object({
  name: yup.string().required(),
  camera: yup.string().nullable(),
  client: yup.string().required(),
  project: yup.string().required(),
  longitude: yup.number().required(),
  latitude: yup.number().required(),
  isPublic: yup.boolean().required(),

  bucket: yup.string(),
  prefix: yup.string(),
});

const { handleSubmit } = useForm<ViewUpdateForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: cameraValue, errorMessage: cameraError } = useField<string | undefined>('camera', 'camera');
const { value: clientValue, errorMessage: clientError } = useField<string>('client', 'client');
const { value: projectValue, errorMessage: projectError } = useField<string>('project', 'project');
const { value: longitudeValue, errorMessage: longitudeError } = useField<number>('longitude', 'longitude');
const { value: latitudeValue, errorMessage: latitudeError } = useField<number>('latitude', 'latitude');
const { value: isPublicValue } = useField<boolean>('isPublic', 'isPublic');

const { value: bucketValue, errorMessage: bucketError } = useField<string | undefined>('bucket', 'bucket');
const { value: prefixValue, errorMessage: prefixError } = useField<string | undefined>('prefix', 'prefix');

const route = useRoute();
const viewStore = useViewStore();
const applicationStore = useApplicationStore();

const viewId = route.params['id'] as string;

const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(false);

const currentView = ref<View>();
const cameras = ref<client.Camera[]>([]);
const filteredCameras = ref<client.Camera[]>([]);
const clients = ref<client.Client[]>([]);
const filteredClients = ref<client.Client[]>([]);
const alertMessage = ref<string>('');
const alertVariant = ref<AlertVariant>(AlertVariant.Light);

const projects = ref<client.Project[]>([]);
const filteredProjects = ref<client.Project[]>([]);

const publicUrl = computed(() => {
  return `https://live.gabrielcam.com/views/${viewId}`;
});

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  const request: client.UpdateViewRequest = {
    name: values.name,
    camera: values.camera || 'undefined',
    client: values.client,
    project: values.project,
    longitude: values.longitude,
    latitude: values.latitude,
    isPublic: values.isPublic,
  };
  const sourceRequest: client.SetViewSourceRequest = {
    provider: client.provider.AWS,
    region: 'eu-west-1',
    bucket: values.bucket ?? '',
    prefix: values.prefix ?? '',
    credentialId: '1234',
  };

  const response = await viewStore.updateView(viewId, request);

  isSubmitting.value = false;
  if (response.error !== undefined) {
    applicationStore.publishErrorNotification({ text: response.error });
    return;
  }
  if (applicationStore.adminMode) {
    const responseSource = await viewStore.updateViewSource(viewId, sourceRequest);
    if (responseSource.error !== undefined) {
      applicationStore.publishErrorNotification({ text: responseSource.error });
      return;
    }
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully updated view.',
    autoCloseMs: 3000,
  });
});

const fetchViewData = async (): Promise<void> => {
  currentView.value = await viewStore.getViewById(viewId);
  nameValue.value = currentView.value.name;
  cameraValue.value = currentView.value.camera;
  clientValue.value = currentView.value.client!;
  projectValue.value = currentView.value.project!;
  longitudeValue.value = currentView.value.longitude ?? 0;
  latitudeValue.value = currentView.value.latitude ?? 0;
  isPublicValue.value = currentView.value.isPublic ?? false;
  bucketValue.value = currentView.value.source?.bucket;
  prefixValue.value = currentView.value.source?.prefix!;

  cameraValue.value = currentView.value.camera; // Save camera ID for fetching live stats

  try {
    // Fetch live camera stats using the cameraId
    const cameraStats = await client.getCameraByIdStatLatest({ cameraId: cameraValue.value } as GetCameraByIdStatLatestData);

    // Check if cameraStats contains valid live latitude and longitude
    if (cameraStats && cameraStats.gnssLatitude != null && cameraStats.gnssLongitude != null) {
      const liveLatitude = cameraStats.gnssLatitude / 1000000;
      const liveLongitude = cameraStats.gnssLongitude / 1000000;

      // Set dynamic alert to inform the user that manual input will override the live data
      alertVariant.value = AlertVariant.Light;
      alertMessage.value = `We have live data for the latitude (${liveLatitude}) and longitude (${liveLongitude}) for this view.
      By using the manual input below, you will overrule the live location on the Map view.`;
    } else {
      // No valid live data or error occurred
      alertVariant.value = AlertVariant.Warning;
      alertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data below will be used instead.`;
    }
  } catch (error) {
    console.error(`Failed to fetch live stats for camera ${cameraValue.value}`, error);
    // Fallback error message is the same as "no valid live data"
    alertVariant.value = AlertVariant.Warning;
    alertMessage.value = `There is no live latitude or longitude coordinates available right now. The manual data below will be used instead.`;
  }
};

onMounted(async () => {
  await fetchViewData();

  cameras.value = (await client.listCameras({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredCameras.value = [...cameras.value];

  clients.value = (await client.listClients({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredClients.value = [...clients.value];

  projects.value = (await client.listProjects({ organisation: applicationStore.activeOrganisation!.id })).data;
  filteredProjects.value = [...projects.value];

  isLoading.value = false;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <form v-else
          @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <h3>View Information</h3>
          <p>Update a view and customise their experience with their logo and a theme.</p>
        </div>

        <div class="fields">
          <div class="field">
            <label for="view-name">Name</label>
            <input id="view-name"
                   v-model="nameValue"
                   type="text">
            <p class="message message-error">
              {{ nameError }}
            </p>
          </div>

          <div class="field">
            <label for="model">Client</label>
            <v-select v-model="clientValue"
                      :options="filteredClients"
                      :reduce="(client: client.Client) => client.id"
                      label="name"
                      @search="
                        (search: string) => {
                          filteredProjects = clients.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <p class="message message-error">
              {{ clientError }}
            </p>
          </div>

          <div class="field">
            <label for="model">Project</label>
            <v-select v-model="projectValue"
                      :options="filteredProjects"
                      :reduce="(project: client.Project) => project.id"
                      label="name"
                      @search="
                        (search: string) => {
                          filteredProjects = projects.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      " />
            <p class="message message-error">
              {{ projectError }}
            </p>
          </div>

          <div class="field">
            <div class="row-fluid">
              <div class="field">
                <label for="model">Camera</label>
                <v-select v-model="cameraValue"
                          :options="filteredCameras"
                          :reduce="(camera: client.Camera) => camera.id"
                          label="serialNumber"
                          @search="
                            (search: string) => {
                              filteredCameras = cameras.filter((x) => {
                                if (!!search.length) return true;
                                return x.serialNumber.toLowerCase().includes(search.toLowerCase());
                              });
                            }
                          " />
              </div>

              <div v-if="cameraValue"
                   class="field">
                <ButtonComponent v-if="applicationStore.adminMode"
                                 :variant="ButtonVariant.Dark"
                                 :to="{ name: PageNames.CameraStatus, params: { id: cameraValue } }"
                                 :is-block-btn="true">
                  Camera Status
                </ButtonComponent>
              </div>
            </div>

            <p class="message message-error">
              {{ cameraError }}
            </p>
          </div>

          <AlertBanner :variant="alertVariant as AlertVariant"
                       :icon-name="IconName.InformationCircleIcon"
                       :icon-style="IconStyle.Outline">
            <template #mainContent>
              <span class="text--size-5">{{ alertMessage }}</span>
            </template>
          </AlertBanner>

          <div class="row-half">
            <div class="field">
              <label for="model">Latitude</label>
              <input id="view-name"
                     v-model="latitudeValue"
                     type="text">
              <p class="message message-error">
                {{ latitudeError }}
              </p>
            </div>
            <div class="field">
              <label for="model">Longitude</label>
              <input id="view-name"
                     v-model="longitudeValue"
                     type="text">
              <p class="message message-error">
                {{ longitudeError }}
              </p>
            </div>
          </div>

          <div class="checkbox-field">
            <label class="checkbox-label"
                   for="isPublic">
              Public
              <span v-if="currentView?.isPublic"
                    class="word-wrap">
                <a :href="publicUrl"
                   class="break-word"
                   target="_blank">{{ publicUrl }}</a>
              </span>
            </label>
            <input id="isPublic"
                   v-model="isPublicValue"
                   type="checkbox">
          </div>
        </div>
      </div>

      <div v-if="applicationStore.adminMode"
           class="field-group">
        <div class="field-group-info">
          <h3>View Source</h3>
          <p>Set a views source</p>
        </div>

        <div class="fields">
          <div class="field">
            <label for="source-bucket">Bucket</label>
            <input id="source-bucket"
                   v-model="bucketValue"
                   type="text">
            <p class="message message-error">
              {{ bucketError }}
            </p>
          </div>
          <div class="field">
            <label for="source-prefix">Prefix</label>
            <input id="source-prefix"
                   v-model="prefixValue"
                   type="text">
            <p class="message message-error">
              {{ prefixError }}
            </p>
          </div>
        </div>
      </div>

      <div class="form-buttons">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Update
        </ButtonComponent>
      </div>
    </form>
  </ContainerCard>
</template>

<style lang="scss" scoped>
.checkbox-field {
  & .checkbox-label {
    margin-left: 10px;

    & .word-wrap {
      display: inline-block;
      width: 100%;
    }

    & .break-word {
      overflow-wrap: break-word;
      word-break: break-all;
      word-wrap: break-word;
    }
  }

  & input[type='checkbox'] {
    flex-shrink: 0;
  }
}
</style>
