<script setup lang="ts">
import { computed, Ref, ref } from 'vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';
import ButtonActions from '@layouts/ButtonActions.vue';

/**
 * We define the props for the form
 */
const props = defineProps({
  name: {
    type: String,
    required: false,
    default: '',
  },
  submitButtonText: {
    type: String,
    required: true,
  },
});

/**
 * We push two events on-submit when the input is valid and on-error when it's not
 */
const push = defineEmits<{
  (e: 'onSubmit', model: Object): void;
  (e: 'onError', error: String): void;
}>();

/**
 * We define the model for the form
 */
const nameReference: Ref = ref<String | undefined>(props.name);

/**
 * We compute the placeholder for the form
 */
const namePlaceholder = computed(() => {
  return nameReference.value ? nameReference.value : 'Name';
});

/**
 * The on submit function. Note the input should be validated here
 */
function onSubmit(): void {
  // If a name for the project has been provided, and it's not the same as the original
  if (nameReference.value && (!props.name || props.name != nameReference.value)) {
    // We submit the new value
    push('onSubmit', { name: nameReference.value });
  } else if (props.name && props.name === nameReference.value) {
    // We push an error if the name is the same as the original
    push('onError', 'Name has already been defined');
  } else if (!nameReference.value) {
    // We push an error if no name has been provided
    push('onError', 'Name has not been defined');
  }
}
</script>

<template>
  <form>
    <div class="row pb-1">
      <div class="col">
        <h6>Enter Project Information:</h6>
      </div>
    </div>

    <div class="row gy-1">
      <div class="col-12">
        <div class="input-group">
          <span id="input-vname"
                class="input-group-text">Name</span>
          <input v-model="nameReference"
                 type="text"
                 class="form-control"
                 :placeholder="namePlaceholder">
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         @click="onSubmit">
          :type="ButtonType.Submit">
          {{ submitButtonText }}
        </ButtonComponent>
      </ButtonActions>

    </div>
  </form>
</template>
