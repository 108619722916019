<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApplicationStore } from '@stores/application';

const applicationStore = useApplicationStore();
const { currentNotification } = storeToRefs(applicationStore);

function closeNotification(): void {
  currentNotification.value = undefined;
}
</script>

<template>
  <div class="notification"
       :class="currentNotification?.class">
    <div class="notification-content">
      <a href="javascript:;"
         class="notification-close"
         @click="closeNotification">Close Notification</a>
      <p>{{ currentNotification?.text }}</p>
    </div>
  </div>
</template>
