<script setup lang="ts">
import { onBeforeMount, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { PageNames } from '@viewModels/enums';

import SubHeader from '@components/SubHeader.vue';

const route = useRoute();
const organisationId: string = route.params['id'] as string;
const organisation = ref({}) as Ref<client.Organisation>;
const pageName = ref<string>('');

function updateHeaderCrumbs(): void {
  if (route.name === PageNames.OrganisationSettings) pageName.value = 'Settings';
  else pageName.value = '';
}

onBeforeMount(async () => {
  organisation.value = await client.getOrganisationById({ organisationId });
  updateHeaderCrumbs();
});
watch(
  () => route.name,
  () => {
    updateHeaderCrumbs();
  }
);
</script>

<template>
  <SubHeader heading="Organisations"
             level="2" />

  <router-view />
</template>
