<script setup lang="ts">
import { PropType } from 'vue';

import { Video } from '@stores/video';

import ViewCardContents from '@components/cards/shared/ViewCardContents.vue';
import ViewCardWrapper from '@components/cards/shared/ViewCardWrapper.vue';

import VideoCardActions from './VideoCardActions.vue';

const props = defineProps({
  resource: {
    type: Object as PropType<Video>,
    required: true,
  },
});
const emit = defineEmits<{
  (e: 'onClick'): void;
}>();

function statusColour(): string {
  if (props.resource.status == 'COMPLETE') return 'status-green';
  if (props.resource.status == 'INITIATED') return 'status-orange';
  if (props.resource.status == 'PROCESSED') return 'status-orange';
  return 'status-red';
}
</script>

<template>
  <ViewCardWrapper v-if="resource">
    <template #card-contents>
      <ViewCardContents :thumbnail="resource.thumbnail"
                        :title="resource.name ?? ''"
                        :overlay="new Date((resource?.length ?? 0) * 1000).toISOString().substring(11, 19)"
                        :status="resource.status"
                        :status-colour="statusColour()"
                        @on-click="resource.status === 'COMPLETE' ? emit('onClick') : null" />
    </template>
    <template #card-actions>
      <VideoCardActions :download-u-r-l="resource.downloadURL"
                        @on-play="emit('onClick')" />
    </template>
  </ViewCardWrapper>
</template>
