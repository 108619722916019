<script lang="ts" setup>
import { BreadcrumbTitles } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import ViewCreationForm from '@components/view/ViewCreationForm.vue';

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: '/views' },
  { title: BreadcrumbTitles.CreateView, active: true },
];
</script>

<template>
  <SubHeader heading="Create View"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <ViewCreationForm />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
