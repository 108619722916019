<script setup lang="ts">
import { useRouter } from 'vue-router';

import {
  EmailAuthCredential,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { firebaseApp } from '@utils/firebase';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

const applicationStore = useApplicationStore();

const router = useRouter();
interface ChangePasswordForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const schema = yup.object({
  password: yup.string().required().min(6).max(20),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password must match')
    .required(),
});

const { handleSubmit, isSubmitting } = useForm<ChangePasswordForm>({
  validationSchema: schema,
});

const { value: currentPasswordValue, errorMessage: currentPasswordError } = useField<string>(
  'currentPassword',
  'currentPassword',
  {
    modelPropName: 'currentPassword',
  }
);
const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password', {
  modelPropName: 'password',
});
const { value: confirmPasswordValue, errorMessage: confirmPasswordError } = useField<string>(
  'confirmPassword',
  'confirmPassword',
  {
    modelPropName: 'confirmPassword',
  }
);

const onSubmit = handleSubmit(async (values, actions) => {
  const authProvider = getAuth(firebaseApp);
  if (!authProvider.currentUser) {
    return;
  }
  if (!authProvider.currentUser.email) {
    return;
  }
  let credentials: EmailAuthCredential | undefined = undefined;
  try {
    credentials = EmailAuthProvider.credential(authProvider.currentUser.email, values.currentPassword);
    await reauthenticateWithCredential(authProvider.currentUser, credentials);
  } catch (error) {
    console.error(error);
    actions.setFieldError('currentPassword', 'Invalid password');
    return;
  }

  try {
    await updatePassword(authProvider.currentUser, values.password);
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated password.',
      autoCloseMs: 3000,
    });

    router.push({ name: PageNames.Account });
  } catch (error: any) {
    console.error(error);
    actions.setErrors({ password: 'An Error Occurred Unexpectedly' });
    return;
  }
});
</script>

<template>
  <form @submit="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="currentPassword">Current Password</label>
        <input id="currentPassword"
               v-model="currentPasswordValue"
               type="password"
               autocomplete="current-password">
        <p class="message message-error">
          {{ currentPasswordError }}
        </p>
      </div>
      <div class="field">
        <label for="password">New Password</label>
        <input id="password"
               v-model="passwordValue"
               type="password"
               autocomplete="new-password">
        <p class="message message-error">
          {{ passwordError }}
        </p>
      </div>
      <div class="field">
        <label for="password-confirm">Confirm password</label>
        <input id="password-confirm"
               v-model="confirmPasswordValue"
               type="password"
               autocomplete="new-password">
        <p class="message message-error">
          {{ confirmPasswordError }}
        </p>
      </div>
    </div>
    <div class="form-buttons">
      <ButtonComponent :disabled="isSubmitting"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Set Password
      </ButtonComponent>
    </div>
  </form>
</template>
