<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { Loader } from '@googlemaps/js-api-loader';

import { useViewStore } from '@stores/view';
import { MapMarker } from '@viewModels/mapMarker';

import Loading from '@components/Loading.vue';

/**
 * The functions that the component emits
 */
type onMarkerClickFunc = (event: MouseEvent, mapMarker: MapMarker, instance: google.maps.Marker) => void;

const push = defineEmits(['onMarkerClick']);

const props = defineProps({
  selectedView: {
    type: String,
    required: false,
    default: '',
  },
});

const viewStore = useViewStore();

const mapElementRef = ref<HTMLElement>();
const markers = ref<Map<string, google.maps.Marker>>(new Map<string, google.maps.Marker>());
const coordinateList = ref<MapMarker[]>([]);
const isLoading = ref(true);

let mapInstance: google.maps.Map | undefined = undefined;

/**
 * The Google Maps API loader
 */
const loader = new Loader({
  apiKey: 'AIzaSyC3Jeaf8cMunQGIfeYDoMe8yTStXjc1YDk',
  version: 'weekly',
  libraries: ['places'],
});

async function loadMapsAPI(): Promise<boolean> {
  try {
    // We need to check to ensure the maps API has been loaded, before we create a new map
    if (typeof google !== 'object' || typeof google.maps !== 'object') {
      await loader.load();
    }
  } catch (error) {
    // We want to check if the error is a reference one
    if (error instanceof ReferenceError) {
      await loader.load();
    } else {
      console.error(error);
      return false; // Explicitly return false if there's an unknown error
    }
  }
  // Ensure true is only returned if everything succeeds or the reference error is handled
  return true;
}

function markerClicked(event: MouseEvent, mapMarker: MapMarker, instance: google.maps.Marker): void {
  push('onMarkerClick', event, mapMarker, instance);
}

/**
 * This method is used to add set of markers to a Google map
 * @param markerList
 * @param googleMapInstance The map Google instance
 * @param onClickHandler The event to add to the marker if we have one
 * @returns Returns a set of marker objects
 */
async function buildMarkers(
  markerList: MapMarker[],
  googleMapInstance: any,
  onClickHandler: onMarkerClickFunc
): Promise<boolean> {
  // We want to construct the markers on the map
  markerList.forEach((marker: MapMarker) => {
    // We obtain the data we need
    if (marker.id == undefined) return;

    const lat = marker.coordinates.latitude;
    const lng = marker.coordinates.longitude;

    // Then we create the marker options
    const markerOptions: google.maps.MarkerOptions = {
      optimized: false,
      map: googleMapInstance,
      position: new google.maps.LatLng(lat, lng),
    };

    // After we add the onclick event
    const markerInstance = new google.maps.Marker(markerOptions);
    marker.originalIcon = (markerInstance?.getIcon() as string) ?? undefined;
    markers.value.set(marker.id, markerInstance);
    if (onClickHandler) {
      markerInstance.addListener('click', (event: MouseEvent) => {
        onClickHandler(event, marker, markerInstance);
      });
    }
  });

  // Finally return true
  return true;
}

onMounted(async () => {
  isLoading.value = true;

  try {
    coordinateList.value = await viewStore.getCamerasLocation();
    // Build the map once the data is fetched
    mapInstance = await buildMap(mapElementRef.value);
    viewSelected();
  } catch (error) {
    console.error('Error loading map or markers:', error);
  } finally {
    isLoading.value = false;
  }
});

/**
 * We create a props watch to override the internal index if needed
 */
watch(
  () => props.selectedView,
  () => {
    viewSelected();
  }
);

function viewSelected(): void {
  if (!mapInstance || !mapElementRef.value || !props.selectedView) return;

  const selectedMarker = coordinateList.value.find((x) => x.id == props.selectedView);

  if (!selectedMarker) return;

  const center = {
    lat: selectedMarker.coordinates.latitude,
    lng: selectedMarker.coordinates.longitude,
  };

  mapInstance.setZoom(8);
  mapInstance.setCenter(center);
  markers.value.forEach((x) => {
    const marker = coordinateList.value.find((x) => x.id == x.id);
    x.setIcon(marker?.originalIcon);
    x.setZIndex(1);
  });

  const markerFound = markers.value.get(props.selectedView);
  markerFound?.setZIndex(2);
  markerFound?.setIcon('/map-pin-dark-blue-iris.png');
}

/**
 * This method is used to build a map
 * @param element The element we want to render the map too
 */
async function buildMap(element: HTMLElement | undefined): Promise<google.maps.Map | undefined> {
  // We add a guard statement to the build method
  if (!element) {
    return undefined;
  }

  await loadMapsAPI();

  const selectedMarker = coordinateList.value.find((x) => x.id == props.selectedView);

  let center = { lat: 0, lng: 0 };
  if (selectedMarker) {
    center = {
      lat: selectedMarker.coordinates.latitude,
      lng: selectedMarker.coordinates.longitude,
    };
  }

  // We create a html reference
  const domRef = ref(element);
  const mapOptions = {
    zoom: 8,
    streetViewControl: false,
    scaleControl: false,
    rotateControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    center: center,
  };

  const googleMapInstance = new google.maps.Map(domRef.value, mapOptions);

  await buildMarkers(
    coordinateList.value,
    googleMapInstance,
    (event: MouseEvent, mapMarker: MapMarker, instance: google.maps.Marker) => {
      markerClicked(event, mapMarker, instance);
    }
  );

  return googleMapInstance;
}
</script>

<template>
  <div v-if="isLoading"
       class="map">
    <Loading :is-absolute-positioned="true" />
  </div>

  <div v-show="!isLoading"
       ref="mapElementRef"
       class="map" />
</template>
