<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import SubHeader from '@components/SubHeader.vue';

const viewStore = useViewStore();
const applicationStore = useApplicationStore();

const { datePickerIsOpen } = storeToRefs(viewStore);

const showNew = applicationStore.canUser(client.Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);
const view = ref<{ name?: string }>({});

const route = useRoute();
const pageName = ref<PageNames>(route.name as PageNames);
const cameraId = route.params['id'] as string;
const viewId: string = route.params['id'] as string;

const isDataReady = ref(false);

onBeforeMount(async () => {
  view.value = await client.getViewById({ viewId });
  isDataReady.value = true;
});

// Watch the route name and update pageName
watch(
  () => route.name,
  (newRouteName) => {
    pageName.value = newRouteName as PageNames;
  }
);

function toggleDatePicker(): void {
  datePickerIsOpen.value = !datePickerIsOpen.value;
}

// Dynamic heading
const headingContent = computed(() => {
  let content = '';

  // If user is logged in
  if (applicationStore.activeUser) {
    if (view.value.name) {
      content += `${view.value.name}`;
    }

    if (pageName.value) {
      if (pageName.value === PageNames.ViewViewer) {
        content += ` ${BreadcrumbTitles.Viewer}`;
      } else if (pageName.value === PageNames.ViewVideos) {
        content += ` ${BreadcrumbTitles.Videos}`;
      } else if (pageName.value === PageNames.ViewInformation) {
        content += ` ${BreadcrumbTitles.Information}`;
      }
    }
  } else {
    content = view.value.name || '';
  }
  return content.trim();
});

// Dynamic breadcrumbs
const breadcrumbs = ref<BreadCrumbItem[]>([]);
watch(
  () => view.value.name,
  (newName) => {
    if (newName) {
      // If user is logged in
      if (applicationStore.activeUser) {
        if (pageName.value === PageNames.ViewViewer) {
          breadcrumbs.value = [
            { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
            { title: newName, active: true },
            { title: BreadcrumbTitles.Viewer, active: true },
          ];
        } else if (pageName.value === PageNames.ViewVideos) {
          breadcrumbs.value = [
            { title: BreadcrumbTitles.AllVideos, to: BreadcrumbPaths.AllVideos },
            { title: newName, active: true },
            { title: BreadcrumbTitles.Videos, active: true },
          ];
        } else if (pageName.value === PageNames.ViewInformation) {
          breadcrumbs.value = [
            { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
            { title: newName, active: true },
            { title: BreadcrumbTitles.Information, active: true },
          ];
        } else {
          breadcrumbs.value = [{ title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews }];
        }
      } else {
        // Logic for breadcrumbs when the user is not logged in
        breadcrumbs.value = [{ title: newName, active: true }];
      }
    }
  }
);
</script>

<template>
  <SubHeader :heading="headingContent"
             level="2">
    <template #buttons>
      <template v-if="pageName === PageNames.ViewViewer">
        <div class="d-lg-none w-100">
          <ButtonComponent :is-block-btn="true"
                           :variant="ButtonVariant.Dark"
                           @click="toggleDatePicker">
            Open Calendar
          </ButtonComponent>
        </div>
      </template>
      <template v-if="pageName === PageNames.ViewVideos && showNew">
        <ButtonComponent :is-block-btn="true"
                         :to="{ name: PageNames.VideoNew, params: { viewId: cameraId } }"
                         :variant="ButtonVariant.Dark">
          New Video
        </ButtonComponent>
      </template>
    </template>
  </SubHeader>

  <Breadcrumb v-if="isDataReady"
              :is-sticky="true"
              :items="breadcrumbs" />

  <router-view />
</template>
