<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

defineProps<{
  password: string;
  confirmPassword: string;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'onBack'): void;
  (e: 'update:password', value: String): void;
  (e: 'update:confirmPassword', value: String): void;
}>();

interface RegisterUserForm {
  password: string;
  confirmPassword: string;
}

const schema = yup.object({
  password: yup.string().required().min(6).max(20),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password must match')
    .required(),
});

const { handleSubmit } = useForm<RegisterUserForm>({
  validationSchema: schema,
});

const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password', {
  modelPropName: 'password',
});
const { value: confirmPasswordValue, errorMessage: confirmPasswordError } = useField<string>(
  'confirmPassword',
  'confirmPassword',
  {
    modelPropName: 'confirmPassword',
  }
);

const onSubmit = handleSubmit(() => {
  emit('onSuccess');
});
</script>

<template>
  <form @submit="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="password">Password</label>
        <input id="password"
               v-model="passwordValue"
               type="password"
               autocomplete="new-password">
        <p class="message message-error">
          {{ passwordError }}
        </p>
      </div>
      <div class="field">
        <label for="password-confirm">Confirm password</label>
        <input id="password-confirm"
               v-model="confirmPasswordValue"
               type="password"
               autocomplete="new-password">
        <p class="message message-error">
          {{ confirmPasswordError }}
        </p>
      </div>
    </div>

    <div class="account-footer">
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       @click="$emit('onBack')">
        Back
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Submit
      </ButtonComponent>
    </div>
  </form>
</template>
