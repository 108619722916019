<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import dayjs from 'dayjs';

import * as client from '@gabrielcam/api-client';

import { useViewStore } from '@stores/view';
import { BadgeVariant, CardVariant } from '@viewModels/enums';

import BadgeComponent from '@components/BadgeComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';

enum Status {
  On = 'ON',
  Off = 'OFF',
}

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});
const isLoading = ref<boolean>(true);
const viewStore = useViewStore();
let view = ref<client.View>();
let stats = ref<client.CameraStat>();
let systemStatus = ref<string>(Status.Off);
let lastCommsDateTime = ref<string>();
let lastImageDateTime = ref<string>('');
let cameraStatus = ref<string>(Status.Off);
let heaterStatus = ref<string>(Status.Off);
let piStatus = ref<string>(Status.Off);

onMounted(async () => {
  isLoading.value = true;
  try {
    await updateData();
  } catch (error) {
    console.error('Error fetching view data:', error);
  }
  isLoading.value = false;
});

function getMinutesBetweenDates(date1: Date, date2: Date): number {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(diffInMilliseconds / (1000 * 60));
}

async function updateData(): Promise<void> {
  view.value = (await viewStore.getViewById(props.viewId)) as client.View;

  if (!view.value?.camera) {
    console.warn('No camera found');
    return;
  }

  lastImageDateTime.value = dayjs(view.value.lastCapturedUtc).local().utc().format('DD/MM/YYYY HH:mm');
  stats.value = await client.getCameraByIdStatLatest({ cameraId: view.value.camera });
  const minutes = getMinutesBetweenDates(new Date(stats.value.timestamp), new Date());
  systemStatus.value = minutes < 15 ? Status.On : Status.Off;
  lastCommsDateTime.value = dayjs(stats.value.timestamp).local().utc().format('DD/MM/YYYY HH:mm');

  cameraStatus.value = stats.value.cameraOn ? Status.On : Status.Off;
  heaterStatus.value = stats.value.heaterOn ? Status.On : Status.Off;
  piStatus.value = stats.value.piOn ? Status.On : Status.Off;
}
</script>

<template>
  <Loading v-if="isLoading"
           class="d-flex align-center justify-center w-100" />

  <section v-else
           class="d-flex flex-column w-100">
    <div class="d-flex align-center mb-20">
      <Heading level="3">
        Status
      </Heading>
    </div>
    <div :style="{ 'background-image': 'url(' + view?.latestImageURL + '/card)' }"
         class="state-panel__container">
      <ContainerCard :narrow-card="true"
                     :variant="CardVariant.TransparentDark"
                     :has-border="true"
                     :margin-bottom="false">
        <Heading class="state-panel__heading"
                 level="3">
          <BadgeComponent :variant="systemStatus === Status.On ? BadgeVariant.Success : BadgeVariant.Danger">
            {{ systemStatus }}
          </BadgeComponent>
          {{ view?.name }}
        </Heading>
      </ContainerCard>

      <ContainerCard :narrow-card="true"
                     :variant="CardVariant.TransparentDark"
                     :has-border="true"
                     class="state-panel__status">
        <div class="state-panel__status--item">
          <span class="state-panel__status--title">Controller</span>
          <BadgeComponent :variant="piStatus === Status.On ? BadgeVariant.Success : BadgeVariant.Danger">
            {{ piStatus }}
          </BadgeComponent>
        </div>
        <div class="state-panel__status--item">
          <span class="state-panel__status--title">Heater</span>
          <BadgeComponent :variant="heaterStatus === Status.On ? BadgeVariant.Success : BadgeVariant.Danger">
            {{ heaterStatus }}
          </BadgeComponent>
        </div>
        <div class="state-panel__status--item">
          <span class="state-panel__status--title">Camera</span>
          <BadgeComponent :variant="cameraStatus === Status.On ? BadgeVariant.Success : BadgeVariant.Danger">
            {{ cameraStatus }}
          </BadgeComponent>
        </div>
      </ContainerCard>

      <ContainerCard :narrow-card="true"
                     :margin-bottom="false"
                     :has-border="true"
                     :variant="CardVariant.TransparentDark"
                     class="d-flex flex-column">
        <BadgeComponent :variant="BadgeVariant.Transparent">
          Last seen: {{ lastCommsDateTime }}
        </BadgeComponent>
        <BadgeComponent :variant="BadgeVariant.Transparent">
          Last image: {{ lastImageDateTime }}
        </BadgeComponent>
      </ContainerCard>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.state-panel {
  &__container {
    width: 100%; // Safari needs this
    height: auto;
    aspect-ratio: 3 / 2;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: clamp($gap-mobile, 1.5vw, $gap-desktop);
    row-gap: $gap-default;
  }

  &__heading {
    color: $neutral-50;
  }

  &__status {
    display: grid;
    grid-template-columns: 1fr;
    gap: $gap-default;
    margin: 0 auto;
    padding: 20px;

    @media screen and (min-width: $breakpoint-lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    &--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: clamp($gap-mobile, 1.5vw, $gap-desktop);
      background-color: $neutral-700;
      color: white;
      text-align: center;
      border-radius: 5px;

      &:last-child {
        border: 0;
      }
    }

    &--title {
      margin-bottom: 5px;
    }
  }
}
</style>
