<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { ButtonType, ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

defineProps<{
  email: string;
  confirmEmail: string;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'onBack'): void;
  (e: 'update:email', value: String): void;
  (e: 'update:confirmEmail', value: String): void;
}>();

interface RegisterUserForm {
  email: string;
  confirmEmail: string;
}

const schema = yup.object({
  email: yup.string().email().required(),
  confirmEmail: yup
    .string()
    .email()
    .oneOf([yup.ref('email')], 'Emails must match')
    .required(),
});

const { handleSubmit } = useForm<RegisterUserForm>({
  validationSchema: schema,
});

const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email', {
  validateOnValueUpdate: false,
  modelPropName: 'email',
});

const { value: confirmEmailValue, errorMessage: confirmEmailError } = useField<string>('confirmEmail', 'confirmEmail', {
  validateOnValueUpdate: false,
  modelPropName: 'confirmEmail',
});

const onSubmit = handleSubmit(() => {
  emit('onSuccess');
});
</script>

<template>
  <form @submit="onSubmit">
    <div class="fields">
      <div class="field">
        <label for="email-address">Email address</label>
        <input id="email-address"
               v-model="emailValue"
               type="email"
               autocomplete="email">
        <p class="message message-error">
          {{ emailError }}
        </p>
      </div>
      <div class="field">
        <label for="email-address-confirm">Confirm email address</label>
        <input id="email-address-confirm"
               v-model="confirmEmailValue"
               type="email"
               autocomplete="email">
        <p class="message message-error">
          {{ confirmEmailError }}
        </p>
      </div>
    </div>

    <div class="account-footer">
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       :is-block-btn="true"
                       @click="$emit('onBack')">
        Back
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Next
      </ButtonComponent>
    </div>
  </form>
</template>
