<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import { Entitlements, GetCameraByIdStatLatestData } from '@gabrielcam/api-client';
import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

// Interface
interface ViewActions {
  viewId: string;
  cameraId?: string;
}

// Props
const props = defineProps<ViewActions>();

// Stores
const applicationStore = useApplicationStore();
const showEdit = applicationStore.canUser(Entitlements.UPDATE_VIEW, applicationStore.activeOrganisation!);
const isCameraLive = ref<boolean>(false);
const isLoading = ref<boolean>(false);

// Check Camera Live Status
async function checkCameraLiveStatus(): Promise<void> {
  isLoading.value = true;

  if (!props.cameraId || !props.viewId) {
    isLoading.value = false;
    return;
  }

  try {
    // Check if the camera returns any data
    await client.getCameraByIdStatLatest({ cameraId: props.cameraId } as GetCameraByIdStatLatestData);
    // Camera is live
    isCameraLive.value = true;
  } catch (e) {
    console.error('Error fetching camera stats:', e);
    // Camera is not live or doesn't exist
    isCameraLive.value = false;
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  checkCameraLiveStatus();
});
</script>

<template>
  <div class="collection-item-actions">
    <ul>
      <li>
        <router-link :to="{ name: PageNames.ViewMap, params: { viewId: props.viewId } }"
                     class="action-map"
                     data-title="View on map"
                     title="View on map">
          View on map
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: PageNames.ViewViewer, params: { id: props.viewId } }"
                     class="action-image"
                     data-title="Open images"
                     title="Open images">
          Open images
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: PageNames.ViewGallery, params: { id: props.viewId } }"
                     class="action-image"
                     data-title="Open gallery"
                     title="Open gallery">
          Open images
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: PageNames.ViewVideos, params: { id: viewId } }"
                     class="action-video"
                     data-title="Watch videos"
                     title="Watch videos">
          Watch videos
        </router-link>
      </li>
      <li v-if="!isLoading && cameraId && isCameraLive && showEdit">
        <router-link :to="{ name: PageNames.CameraStatus, params: { id: cameraId } }"
                     class="action-camera-status"
                     data-title="Camera Status"
                     title="Camera Status">
          Status
        </router-link>
      </li>
    </ul>
  </div>
</template>
