<script setup lang="ts">
import { onBeforeMount, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';

const route = useRoute();
const userId: string = route.params['id'] as string;
const user = ref({}) as Ref<client.User>;
const roles = ref([]) as Ref<client.Role[]>;

onBeforeMount(async () => {
  user.value = await client.getUserById({ userId });
  roles.value = (await client.listRoles()).data.filter((role) => role.userAssignable);
});
</script>

<template>
  <ContainerCard>
    <form @submit="() => {}">
      <div class="field-group">
        <div class="field-group-info">
          <h3>User Information</h3>
          <p>Some info about the user.</p>
        </div>
        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label id="input-vname"
                     for="displayName">Display Name</label>
              <input id="displayName"
                     v-model="user.displayName"
                     disabled
                     type="text">
            </div>
            <div class="field">
              <label id="input-vname"
                     for="email">Email</label>
              <input id="email"
                     v-model="user.email"
                     disabled
                     type="text">
            </div>
          </div>
          <div class="row-half">
            <div class="field">
              <label id="input-vname"
                     for="status">Status</label>
              <input id="status"
                     v-model="user.status"
                     disabled
                     type="text">
            </div>
          </div>
        </div>
      </div>
    </form>
  </ContainerCard>
</template>
