<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useCurrentRoute } from '@utils/getCurrentRoute';

import { IconName, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubNav, { SubNavItem } from '@components/layout/SubNav.vue';
import SubHeader from '@components/SubHeader.vue';

interface NavItem {
  to?: string;
  active?: boolean;
}

const { currentRouteMetaTitle } = useCurrentRoute();
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
const route = useRoute();
const cameraId = route.params['id'] as string;
const breadcrumbs = ref<BreadCrumbItem[]>([]);
const breadcrumbKey = ref(0);
const subNavKey = ref(0);
const subNavItems = ref<SubNavItem[]>([]);

// Fetch the current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    updateBreadcrumbs();
    updateSubNavItems();
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false; // Ensure this happens last
  }
});

// Watch for route changes and update breadcrumbs and subnav accordingly
watch(
  [() => route.name, () => route.meta['title'] as string | ''],
  () => {
    updateBreadcrumbs();
    updateSubNavItems();
  },
  { immediate: true }
);

function updateBreadcrumbs(): void {
  if (currentCamera.value?.serialNumber) {
    breadcrumbs.value = [
      { title: 'All Views', to: '/views' },
      { title: 'Cameras', to: '/cameras' },
      { title: `${currentCamera.value.serialNumber} ${currentRouteMetaTitle.value}`, active: true },
    ];
    breadcrumbKey.value += 1; // HACK: Increment key to force re-render
  } else {
    breadcrumbs.value = [];
  }
}

function updateSubNavItems(): void {
  subNavItems.value = setActiveState<SubNavItem>(
    [
      {
        title: 'Status',
        to: `/cameras/${cameraId}/status`,
        viewPermission: true,
        iconName: IconName.AdjustmentsHorizontalIcon,
        iconStyle: IconStyle.Outline,
      },
      {
        title: 'Schedule',
        to: `/cameras/${cameraId}/schedule`,
        viewPermission: true,
        iconName: IconName.ClockIcon,
        iconStyle: IconStyle.Outline,
      },
      {
        title: 'Settings',
        to: `/cameras/${cameraId}/settings`,
        viewPermission: true,
        iconName: IconName.WrenchScrewdriverIcon,
        iconStyle: IconStyle.Outline,
      },
      {
        title: 'Power',
        to: `/cameras/${cameraId}/power`,
        viewPermission: true,
        iconName: IconName.BoltIcon,
        iconStyle: IconStyle.Outline,
      },
      {
        title: 'Logs',
        to: `/cameras/${cameraId}/logs`,
        viewPermission: true,
        iconName: IconName.BookOpenIcon,
        iconStyle: IconStyle.Outline,
      },
      // {
      //   title: 'Network',
      //   to: `/cameras/${cameraId}/network`,
      //   viewPermission: true,
      //   iconName: IconName.WifiIcon,
      //   iconStyle: IconStyle.Outline,
      // },
      // {
      //   title: 'Device',
      //   to: `/cameras/${cameraId}/device`,
      //   viewPermission: true,
      //   iconName: IconName.DocumentTextIcon,
      //   iconStyle: IconStyle.Outline,
      // },
      // {
      //   title: 'API',
      //   to: `/cameras/${cameraId}/api`,
      //   viewPermission: true,
      //   iconName: IconName.ArrowPathRoundedSquareIcon,
      //   iconStyle: IconStyle.Outline,
      // },
    ],
    route.path
  );
  subNavKey.value += 1; // HACK: Increment key to force re-render
}

// Set active state for nav items based on the current route
function setActiveState<T extends NavItem>(items: T[], currentPath: string): T[] {
  return items.map((item) => ({
    ...item,
    active: item.to === currentPath,
  }));
}

const pageHeading = computed(() => {
  return currentCamera.value?.serialNumber + ' ' + currentRouteMetaTitle.value;
});
</script>

<template>
  <SubHeader :heading="isLoading ? 'Loading...' : pageHeading"
             level="2" />
  <Breadcrumb :key="breadcrumbKey"
              :is-sticky="true"
              :items="breadcrumbs" />

  <SubNav :key="subNavKey"
          :is-loading="isLoading"
          :sub-nav-items="subNavItems"
          heading="Camera Options"
          heading-level="3" />

  <router-view />
</template>
