<script setup lang="ts">
import { PropType, ref, toRefs, watch } from 'vue';

import * as client from '@gabrielcam/api-client';

import ViewCardContents from '@components/cards/shared/ViewCardContents.vue';
import ViewCardWrapper from '@components/cards/shared/ViewCardWrapper.vue';

import GalleryCardActions from './GalleryCardActions.vue';

const props = defineProps({
  resource: {
    type: Object as PropType<client.Image>,
    required: true,
  },
  selected: {
    type: Boolean,
    required: false,
  },
});

const thumbnail = ref<string | undefined>();
const overlay = ref<string>('');

defineEmits<{
  (e: 'onDelete', value: client.Image): void;
  (e: 'onClick'): void;
}>();

function update(): void {
  thumbnail.value = `${resource.value.sourceURL}/thumbnail`;
  if (props.resource.originalFileName == undefined) thumbnail.value = undefined;

  if (props.resource.capturedAt !== undefined) {
    overlay.value = `${new Date(props.resource.capturedAt).toLocaleDateString()} ${new Date(
      props.resource.capturedAt
    ).toLocaleTimeString()}`;
  } else {
    overlay.value = '';
  }
}

const { resource } = toRefs(props);
watch(resource, update, { deep: true });
update();
</script>

<template>
  <ViewCardWrapper v-if="resource"
                   :selected="selected">
    <template #card-contents>
      <ViewCardContents :thumbnail="thumbnail"
                        :title="resource.originalFileName!"
                        :overlay="overlay"
                        @on-click="$emit('onClick')" />
    </template>

    <template #card-actions>
      <GalleryCardActions :image-id="resource.id"
                          @delete="$emit('onDelete', resource)" />
    </template>
  </ViewCardWrapper>
</template>
