<script setup lang="ts">
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';

const emit = defineEmits<{
  (e: 'delete'): void;
}>();

const applicationStore = useApplicationStore();
const showDelete = applicationStore.canUser(Entitlements.DELETE_IMAGE, applicationStore.activeOrganisation!);
</script>

<template>
  <div class="collection-item-actions">
    <ul>
      <li v-if="showDelete">
        <a href="javascript:;"
           class="action-delete"
           data-title="Delete"
           @click="emit('delete')">Delete</a>
      </li>
    </ul>
  </div>
</template>
