import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

/**
 * Checks if a route needs to be authenticated by inspecting the route's meta field
 */
export default function (to: RouteLocationNormalized): RouteLocationRaw | void {
  if (to.meta['public']) return;

  const applicationStore = useApplicationStore();
  const user = applicationStore.activeUser;

  const query: { [key: string]: any } = {};
  if (to.query['apiKey'] && to.query['oobCode'] && to.query['mode']) {
    query['apiKey'] = to.query['apiKey'];
    query['oobCode'] = to.query['oobCode'];
    query['mode'] = to.query['mode'];
  }

  query['continueUrl'] = to.path !== '/' ? to.path : '/views';

  if (!user) return { name: PageNames.Login, query };
}
