<script lang="ts" setup>
import { onBeforeMount, reactive, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import type { GroupedEntitlements } from '@utils/groupRoleEntitlements';
import { groupEntitlements } from '@utils/groupRoleEntitlements';

import { useApplicationStore } from '@stores/application';
import { AssignedRoleResourceViewModel, ResourceViewModel } from '@viewModels/assignedRoleResourceViewModel';
import { ButtonSize, ButtonVariant, CardVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import AddRoleModal from '@components/account_forms/AddRoleModal.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import HorizontalRule from '@components/HorizontalRule.vue';
import ModalComponent from '@components/ModalComponent.vue';
import { TableColumn } from '@components/table/models/Table';
import TLSTable from '@components/table/Table.vue';
import EmptyState from '@layouts/EmptyState.vue';

enum TableHeader {
  View = 'View',
  Role = 'Role',
  Actions = 'Actions',
}

enum RoleType {
  View = 'View',
  Project = 'Project',
  Organisation = 'Organisation',
}

const route = useRoute();
const applicationStore = useApplicationStore();

const userId: string = route.params['id'] as string;
const isLoading = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);

const user = ref({}) as Ref<client.User>;
const roles = ref([]) as Ref<client.Role[]>;

const filteredOrganisationRoles = ref<client.Role[]>([]);
const filteredViewRoles = ref<client.Role[]>([]);
const filteredProjectRoles = ref<client.Role[]>([]);

const rolesOnOrgs = ref<client.AssignedRole[]>([]);
const rolesOnProjects = ref<client.AssignedRole[]>([]);
const rolesOnViews = ref<client.AssignedRole[]>([]);
const rolesOnClients = ref<client.AssignedRole[]>([]);

const organisationsAssigned = ref<AssignedRoleResourceViewModel[]>([]);
const projectsAssigned = ref<AssignedRoleResourceViewModel[]>([]);
const viewsAssigned = ref<AssignedRoleResourceViewModel[]>([]);

const organisations = ref<ResourceViewModel[]>([]);
const projects = ref<ResourceViewModel[]>([]);
const views = ref<ResourceViewModel[]>([]);

const selectedRoleEntitlements = ref<client.Entitlements[]>([]);
const groupedEntitlements = groupEntitlements() as GroupedEntitlements;

const viewRoleModalOpen = ref<boolean>(false);
const projectRoleModalOpen = ref<boolean>(false);
const organisationRoleModalOpen = ref<boolean>(false);
const isEntitlementsModalVisible = ref<boolean>(false);

const isDeleteModalVisible = ref<boolean>(false);
const rowToDelete = ref<number>(0);
const roleTypeName = ref<string>('');

const openEntitlementsModal = (row: { entitlements: client.Entitlements[] }): void => {
  selectedRoleEntitlements.value = row.entitlements;
  isEntitlementsModalVisible.value = true;
};

const closeEntitlementsModal = (): void => {
  isEntitlementsModalVisible.value = false;
  selectedRoleEntitlements.value = [];
};

const openDeleteModal = (rowIndex: number, roleType: string): void => {
  roleTypeName.value = roleType;
  rowToDelete.value = rowIndex;
  isDeleteModalVisible.value = true;
};

const closeDeleteModal = (): void => {
  isDeleteModalVisible.value = false;
};

const columns: TableColumn[] = [
  {
    label: TableHeader.View,
    field: 'resourceName',
    headerClasses: 'text--white-space-nowrap',
    width: '50%',
  },
  {
    label: TableHeader.Role,
    field: 'role',
    headerClasses: 'text--white-space-nowrap',
    width: '49%',
  },
  {
    label: TableHeader.Actions,
    field: 'id',
    headerClasses: '',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

onBeforeMount(async () => {
  await load();
});

async function load(): Promise<void> {
  isLoading.value = true;
  viewsAssigned.value = [];
  organisationsAssigned.value = [];
  projectsAssigned.value = [];
  user.value = await client.getUserById({ userId });
  roles.value = (await client.listRoles()).data.filter((role) => role.userAssignable);
  filteredViewRoles.value = roles.value.filter((x) => x.relType == client.Resources.VIEW);
  filteredOrganisationRoles.value = roles.value.filter((x) => x.relType == client.Resources.ORGANISATION);
  filteredProjectRoles.value = roles.value.filter((x) => x.relType == client.Resources.PROJECT);

  rolesOnOrgs.value = user.value.roles.filter((x) => x.relType == client.Resources.ORGANISATION);
  rolesOnViews.value = user.value.roles.filter((x) => x.relType == client.Resources.VIEW);
  rolesOnClients.value = user.value.roles.filter((x) => x.relType == client.Resources.CLIENT);
  rolesOnProjects.value = user.value.roles.filter((x) => x.relType == client.Resources.PROJECT);

  const organisationsResult = await client.listOrganisations();
  organisations.value = organisationsResult.data
    .filter((x) => rolesOnOrgs.value.find((y) => y.relId == x.id) == undefined)
    .map((x) => ({
      id: x.id,
      name: x.name,
    }));

  const projectsResult = await client.listProjects({ organisation: applicationStore.activeOrganisation!.id });
  projects.value = projectsResult.data
    .filter((x) => rolesOnProjects.value.find((y) => y.relId == x.id) == undefined)
    .map((x) => ({
      id: x.id,
      name: x.name,
    }));

  const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
  views.value = viewsResult.data
    .filter((x) => rolesOnViews.value.find((y) => y.relId == x.id) == undefined)
    .map((x) => ({
      id: x.id,
      name: x.name,
    }));

  let tempOrganisationsAssigned: AssignedRoleResourceViewModel[] = [];
  rolesOnOrgs.value.forEach((roleOnOrg) => {
    const view = organisationsResult.data.find((x) => x.id == roleOnOrg.relId);
    const role = roles.value.find((x) => x.id == roleOnOrg.id);
    if (view == undefined) return;
    if (role == undefined) return;
    tempOrganisationsAssigned.push({
      index: user.value.roles.indexOf(roleOnOrg), // should be using an ID!
      roleId: roleOnOrg.id,
      role: role.shortName,
      relType: roleOnOrg.relType,
      resourceName: view.name,
      editMode: false,
      entitlements: role.entitlements,
    });
  });
  organisationsAssigned.value = tempOrganisationsAssigned;

  let tempProjectsAssigned: AssignedRoleResourceViewModel[] = [];
  rolesOnProjects.value.forEach((roleOnProject) => {
    const view = projectsResult.data.find((x) => x.id == roleOnProject.relId);
    const role = roles.value.find((x) => x.id == roleOnProject.id);
    if (view == undefined) return;
    if (role == undefined) return;
    tempProjectsAssigned.push({
      index: user.value.roles.indexOf(roleOnProject), // should be using an ID!
      roleId: roleOnProject.id,
      role: role.shortName,
      relType: roleOnProject.relType,
      resourceName: view.name,
      editMode: false,
      entitlements: role.entitlements,
    });
  });
  projectsAssigned.value = tempProjectsAssigned;

  let tempViewsAssigned: AssignedRoleResourceViewModel[] = [];
  rolesOnViews.value.forEach((rolesOnView) => {
    const view = viewsResult.data.find((x) => x.id == rolesOnView.relId);
    const role = roles.value.find((x) => x.id == rolesOnView.id);
    if (view == undefined) return;
    if (role == undefined) return;
    tempViewsAssigned.push({
      index: user.value.roles.indexOf(rolesOnView), // should be using an ID!
      roleId: rolesOnView.id,
      role: role.shortName,
      relType: rolesOnView.relType,
      resourceName: view.name,
      editMode: false,
      entitlements: role.entitlements,
    });
  });
  viewsAssigned.value = tempViewsAssigned;
  isLoading.value = false;
}

async function removeRole(roleIndex: number): Promise<void> {
  await client.revokeUserByIdRoleByIndex({ userId, roleIndex });

  closeDeleteModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted user role.',
    autoCloseMs: 3000,
  });
  await load();
}

async function addRole(roleId: string, relId: string): Promise<void> {
  isSubmitting.value = true;
  if (applicationStore.activeOrganisation == undefined) return;

  try {
    await client.assignUserByIdRoles({
      userId,
      requestBody: { id: roleId, relId: relId, organisation: applicationStore.activeOrganisation!.id },
    });
    applicationStore.publishSuccessNotification({
      text: 'Successfully added role.',
      autoCloseMs: 3000,
    });

    await load();
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }
}
</script>

<template>
  <form>
    <ContainerCard>
      <Heading level="2"
               :has-bottom-margin="true">
        User Organisations
      </Heading>
      <template v-for="role in filteredOrganisationRoles"
                :key="role.id">
        <p class="text--bold mb-0">
          {{ role.shortName }}
        </p>
        <p>{{ role.description }}</p>
      </template>

      <TLSTable :columns="columns"
                :data="organisationsAssigned"
                :is-loading="isLoading"
                :sortable="table.sortable"
                class="mb-20">
        <template #table-empty>
          <EmptyState heading-text="No users found"
                      :button-variant="ButtonVariant.Dark"
                      button-text="Add Organisation Role"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Solid"
                      @btn-click="() => (organisationRoleModalOpen = true)" />
        </template>

        <template #cell="{ row, column }">
          <div v-if="column.label === TableHeader.Actions"
               class="d-flex justify-between gap-20">
            <ButtonComponent :icon-name="IconName.UserGroupIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="openEntitlementsModal(row)">
              Roles
            </ButtonComponent>
            <ButtonComponent :icon-name="IconName.TrashIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Danger"
                             @click="openDeleteModal(row.index, RoleType.Organisation)">
              Delete
            </ButtonComponent>
          </div>
        </template>
      </TLSTable>
      <div class="d-flex flex-column flex-lg-row justify-lg-end gap-10">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting || isLoading"
                         :is-block-btn="true"
                         @click="() => (organisationRoleModalOpen = true)">
          Add Organisation Role
        </ButtonComponent>
      </div>
    </ContainerCard>

    <ContainerCard>
      <Heading level="2"
               :has-bottom-margin="true">
        User Projects
      </Heading>
      <template v-for="role in filteredProjectRoles"
                :key="role.id">
        <p class="text--bold mb-0">
          {{ role.shortName }}
        </p>
        <p>{{ role.description }}</p>
      </template>

      <TLSTable :columns="columns"
                :data="projectsAssigned"
                :is-loading="isLoading"
                :sortable="table.sortable">
        <template #table-empty>
          <EmptyState heading-text="No roles found"
                      :button-variant="ButtonVariant.Dark"
                      button-text="Add Project Role"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Solid"
                      @btn-click="() => (projectRoleModalOpen = true)" />
        </template>

        <template #cell="{ row, column }">
          <div v-if="column.label === TableHeader.Actions"
               class="d-flex justify-center gap-10 px-15">
            <ButtonComponent :icon-name="IconName.UserGroupIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="openEntitlementsModal(row)">
              Roles
            </ButtonComponent>
            <ButtonComponent :icon-name="IconName.TrashIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Danger"
                             @click="openDeleteModal(row.index, RoleType.Project)">
              Delete
            </ButtonComponent>
          </div>
        </template>
      </TLSTable>

      <div class="d-flex flex-column flex-lg-row justify-lg-end gap-10">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting || isLoading"
                         :is-block-btn="true"
                         @click="() => (projectRoleModalOpen = true)">
          Add Project Role
        </ButtonComponent>
      </div>
    </ContainerCard>

    <ContainerCard>
      <Heading level="2"
               :has-bottom-margin="true">
        User Views
      </Heading>
      <template v-for="role in filteredViewRoles"
                :key="role.id">
        <p class="text--bold mb-0">
          {{ role.shortName }}
        </p>
        <p>{{ role.description }}</p>
      </template>

      <TLSTable :columns="columns"
                :data="viewsAssigned"
                :is-loading="isLoading"
                :sortable="table.sortable">
        <template #table-empty>
          <EmptyState heading-text="No roles found"
                      :button-variant="ButtonVariant.Dark"
                      button-text="Add View Role"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Solid"
                      @btn-click="() => (viewRoleModalOpen = true)" />
        </template>
        <template #cell="{ row, column }">
          <div v-if="column.label === TableHeader.Actions"
               class="d-flex justify-center gap-10 px-15">
            <ButtonComponent :icon-name="IconName.UserGroupIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             :disabled="selectedRoleEntitlements.length === 0"
                             @click="openEntitlementsModal(row)">
              Roles
            </ButtonComponent>
            <ButtonComponent :icon-name="IconName.TrashIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Danger"
                             @click="openDeleteModal(row.index, RoleType.View)">
              Delete
            </ButtonComponent>
          </div>
        </template>
      </TLSTable>

      <div class="d-flex flex-column flex-lg-row justify-lg-end gap-10">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting || isLoading"
                         :is-block-btn="true"
                         @click="() => (viewRoleModalOpen = true)">
          Add View Role
        </ButtonComponent>
      </div>
    </ContainerCard>
  </form>

  <!-- Delete Role Modal -->
  <ModalComponent v-if="rowToDelete > 0"
                  :visible="isDeleteModalVisible"
                  :heading-title="`Delete ${roleTypeName} Role`"
                  @on-close="closeDeleteModal">
    <template #modal-content>
      <div class="d-flex flex-column gap-10">
        <p class="text--bold">
          Are you sure you want to delete this {{ roleTypeName.toLowerCase() }} role?
        </p>
      </div>
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       @click="closeDeleteModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :variant="ButtonVariant.Danger"
                       :is-block-btn="true"
                       @click="removeRole(rowToDelete)">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>

  <!-- Roles Modal -->
  <ModalComponent v-if="selectedRoleEntitlements.length > 0"
                  :visible="isEntitlementsModalVisible"
                  heading-title="Current Role Entitlements"
                  @on-close="closeEntitlementsModal">
    <template #modal-content>
      <ContainerCard v-for="(group, entity) in groupedEntitlements"
                     :key="entity"
                     :variant="CardVariant.Light"
                     :has-border="true"
                     :narrow-card="true"
                     class="d-flex flex-column gap-10">
        <Heading level="3">
          {{ entity }} Roles
        </Heading>
        <div v-for="(entitlement, index) in group.entitlements"
             :key="entitlement"
             class="d-flex flex-column flex-lg-row gap-column-10">
          <span class="text--bold">{{ group.entitlements[index] }} </span>
          <span class="d-none d-lg-inline">-</span>
          <span>{{ group.descriptions[index] }}</span>
          <HorizontalRule v-if="index !== group.entitlements.length - 1"
                          class="d-lg-none" />
        </div>
      </ContainerCard>
    </template>
    <template #modal-footer>
      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       @click="closeEntitlementsModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>

  <!-- Add Role Modal-->
  <AddRoleModal v-if="viewRoleModalOpen"
                :resources="views"
                :roles="filteredViewRoles"
                :type="client.Resources.VIEW"
                @on-submit="addRole"
                @on-close="() => (viewRoleModalOpen = false)" />
  <AddRoleModal v-if="projectRoleModalOpen"
                :resources="projects"
                :roles="filteredProjectRoles"
                :type="client.Resources.PROJECT"
                @on-submit="addRole"
                @on-close="() => (projectRoleModalOpen = false)" />
  <AddRoleModal v-if="organisationRoleModalOpen"
                :resources="organisations"
                :roles="filteredOrganisationRoles"
                :type="client.Resources.ORGANISATION"
                @on-submit="addRole"
                @on-close="() => (organisationRoleModalOpen = false)" />
</template>
