<template>
  <aside class="app--sidebar">
    <slot name="navbar" />
  </aside>
</template>

<style lang="scss">
@use '@scss/variables' as *;

.app--sidebar {
  background-color: var(--primary-color, $primary-color);
  display: flex;
  flex-shrink: 0;
  height: 80px;
  position: relative;
  width: 100%;

  @media screen and (min-width: $breakpoint-lg) {
    flex-direction: column;
    height: 100%;
    transition: width 0.15s ease;
    width: 230px;
    z-index: 15;
  }

  header {
    background-color: var(--primary-color, $primary-color);
    width: 100%;
    z-index: 16;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    a {
      &:link,
      &:visited {
        background-image: var(--logo, $logo);
        background-position: center left;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 50px;
        margin: 15px;
        text-indent: -3000em;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        opacity: 0.8;
        outline: none;
      }
    }
  }

  .sidebar-container {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 15;

    &:before {
      background-color: var(--primary-color, $primary-color);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 16;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .sidebar-container {
      flex-direction: column;
      height: 100vh;
      justify-content: start;
      transition: width 0.15s ease;
      width: 230px;

      &:before {
        box-shadow: inset -1px 0 0 $white-opacity-25;
        z-index: -1;
      }
    }
  }

  &-logo {
    display: none;

    @media screen and (min-width: $breakpoint-lg) {
      background-image: var(--logo, $logo);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      height: 70px;
      margin: 30px auto;
      width: calc(100% - 30px);
      z-index: 16;
    }
  }

  .menu-toggle {
    align-items: center;
    display: flex;
    height: 42px;
    justify-content: center;
    padding: 0;
    width: 42px;
    z-index: 17;

    @media screen and (min-width: $breakpoint-lg) {
      display: none;
    }

    svg {
      fill: $neutral-200;
      height: 40px;
      padding: 2px;
      width: 40px;
    }
  }

  .menu-container {
    background-color: var(--primary-color, $primary-color);
    bottom: -9999px;
    left: 0;
    position: absolute;
    transition:
      top 0.15s ease,
      visibility 0.15s;
    visibility: hidden;
    width: 100%;
    z-index: 15;

    @media screen and (min-width: $breakpoint-lg) {
      background-color: transparent !important;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      top: 0;
      visibility: visible;
      width: inherit;
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid $white-opacity-25;
    color: $neutral-400;
    margin: 5px 1px;
  }

  .menu {
    column-gap: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    z-index: 16;

    @media screen and (min-width: $breakpoint-lg) {
      align-items: stretch;
    }

    &-organisation {
      border-bottom: 1px solid $white-opacity-25;
      color: $neutral-400;
      margin-bottom: 10px;
      padding: 5px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-select:not(.vs--open.menu-organisation-select) {
        margin: 0 auto $margin-bottom auto;
        width: 100% !important;
        color: inherit !important;
        background-color: $black-opacity-25;
        border-color: $white-opacity-25;
        outline: 1px solid $white-opacity-25;
        border-radius: 5px;

        & .vs__selected {
          margin: 0 !important;
        }
      }
    }

    & .vs--open.menu-organisation-select {
      margin: 0 !important;
      width: 100% !important;
      color: inherit !important;
    }

    svg.menu-icon {
      height: 20px;
      width: 20px;
    }

    svg.arrow-icon {
      height: 20px;
      margin-left: auto;
      transition: transform 0.3s ease;
      width: 20px;
    }

    svg.rotate-180 {
      transform: rotate(180deg);
    }

    li,
    div.menu-footer {
      @media screen and (min-width: $breakpoint-lg) {
        display: block;
      }

      .menu-dropdown {
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        color: $neutral-200;
        column-gap: 8px;
        display: flex;
        justify-content: flex-start;
        margin: auto;
        outline: 1px solid transparent;
        overflow: hidden;
        padding: 6px 10px;
        text-decoration: none;
        transition: all 300ms linear;
        white-space: nowrap;
        width: 100%;

        &:hover,
        &:focus,
        &:active {
          background-color: $black-opacity-25;
          cursor: pointer;
          outline: 1px solid $white-opacity-25;
        }

        &.has-submenu {
          background-color: unset;
          cursor: default;
          margin-bottom: -6px;
        }

        .menu-header-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.menu-header {
        border-bottom: 1px solid $white-opacity-25;
        color: $neutral-400;
        margin-bottom: 15px;
        padding: 5px 0;

        @media screen and (min-width: $breakpoint-lg) {
          color: $neutral-200;
          display: block;
          font-size: 0.85em;
          margin: 10px 0 15px -17px !important;
          opacity: 0.5;
          padding-left: 34px;

          a {
            &:link,
            &:visited {
              margin: 0;
              padding: 0;
              pointer-events: none;
            }
          }

          @media screen and (max-height: 600px) {
            background: $white-opacity-50;
            height: 1px;
            margin: 4px 0 6px;
            text-indent: -3000px;
          }
        }
      }

      a {
        &:link,
        &:visited {
          align-items: center;
          border-radius: 5px;
          color: $neutral-200;
          column-gap: 8px;
          display: flex;
          justify-content: flex-start;
          outline: 1px solid transparent;
          overflow: hidden;
          padding: 8px 10px;
          text-decoration: none;
          transition: all 300ms linear;
          white-space: nowrap;

          @media screen and (min-width: $breakpoint-lg) {
            @media screen and (max-height: 700px) {
              padding: 8px 46px 8px 42px;
            }

            @media screen and (max-height: 640px) {
              padding: 6px 48px 6px 40px;
            }
          }
        }

        &:active,
        &:hover,
        &:focus,
        &:focus-visible,
        &.router-link-exact-active {
          background-color: $black-opacity-25 !important;
          outline: 1px solid $white-opacity-25 !important;
        }

        &.router-link-exact-active {
          pointer-events: none;
        }
      }

      .submenu {
        display: flex;
        flex-direction: column;
        padding: 8px 0 8px 27px;
        row-gap: 8px;

        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &.menu-primary {
      line-height: 1;
      padding: 20px 20px 0;

      @media screen and (min-width: $breakpoint-lg) {
        padding: 0 20px;
      }
    }

    &.menu-secondary {
      background-color: $black-opacity-25;
      margin-top: 30px;
      padding: 10px 25px;
      position: relative;
      z-index: 15;

      @media screen and (min-width: $breakpoint-lg) {
        padding: 20px 15px;
      }

      .menu-item {
        align-items: center;
        column-gap: 8px;
        display: flex;
      }

      .menu-footer a {
        margin: 0 !important;
      }

      svg.footer-icon {
        height: 24px;
        width: 24px;
      }

      li a:hover,
      li a:focus,
      li a:focus-visible {
        background-color: $white-opacity-25;
      }
    }
  }
}

.app-container.mobile-nav-open {
  .app--sidebar {
    .menu-container {
      @media screen and (max-width: $breakpoint-lg) {
        border-radius: 15px 15px 0 0;
        bottom: 80px;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        transition:
          bottom 200ms ease,
          visibility 0.15s ease;
        visibility: visible;
      }
    }
  }
}
</style>
