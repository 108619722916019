<script setup lang="ts">
import { reactive, ref } from 'vue';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonSize, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { TableColumn } from '@components/directory/models/Table';
import TLSTable from '@components/directory/Table.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';

const enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const showDeleteClientModel = ref(false);
const deleteClientModelFunc = ref<Function>(() => deleteClient);
const clientName = ref('');

const applicationStore = useApplicationStore();

const showNew = applicationStore.canUser(client.Entitlements.CREATE_CLIENT, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CLIENT, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CLIENT, applicationStore.activeOrganisation!);

async function obtainClientList(): Promise<client.Client[]> {
  const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
  return res.data;
}

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteClientModel.value = false;
}

async function showDeleteConfirmModal(row: client.Client, redraw: Function): Promise<void> {
  showDeleteClientModel.value = true;
  clientName.value = row.name;

  deleteClientModelFunc.value = async () => {
    await deleteClient(row);
    await redraw();
  };
}

async function deleteClient(row: client.Client): Promise<void> {
  await client.deleteClientById({ clientId: row.id });

  closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted client.',
    autoCloseMs: 3000,
  });
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    showActions: false,
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    showActions: false,
    headerClasses: 'status',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
  { title: BreadcrumbTitles.Clients, active: true },
];
</script>

<template>
  <SubHeader heading="Clients"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="showNew"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :to="{ name: PageNames.ClientCreate }"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusCircleIcon"
                       :icon-style="IconStyle.Solid">
        Add Client
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div>
          <TLSTable :retrieve-data="obtainClientList"
                    :columns="table.columns"
                    :total="table.totalRecordCount"
                    :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No clients found"
                          strap-line="Add a new client"
                          :button-variant="ButtonVariant.Dark"
                          button-text="Add Client"
                          :icon-name="IconName.UserGroupIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.ClientCreate }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Actions">
                <div class="d-flex justify-between gap-20">
                  <ButtonComponent v-if="showUpdate"
                                   :to="{ name: PageNames.ClientSettings, params: { id: row.id } }"
                                   :icon-name="IconName.AdjustmentsHorizontalIcon"
                                   :icon-style="IconStyle.Outline"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Edit
                  </ButtonComponent>
                  <ButtonComponent v-if="showDelete"
                                   :icon-name="IconName.TrashIcon"
                                   :icon-style="IconStyle.Outline"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row, obtainClientList)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </TLSTable>
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Client Modal -->
  <ModalComponent :visible="showDeleteClientModel"
                  heading-title="Delete Client"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--bold">{{ clientName }}</span> client?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteClientModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
