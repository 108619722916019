import { reactive } from 'vue';

const state = reactive({
  isMobile: window.innerWidth <= 960,
});

const updateIsMobile = () => {
  state.isMobile = window.innerWidth <= 960;
};

export function useMobileDetection() {
  return {
    isMobile: state.isMobile,
    updateIsMobile,
  };
}

export { state as isMobileState, updateIsMobile };
