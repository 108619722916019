import { ref } from 'vue';

import { defineStore } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from './application';

export interface PaginationOptions {
  pageNumber: number;
  pageSize: number;
}

export interface PagedResult<T> {
  total_count: number;
  offset: number;
  pageSize?: number;
  pageNumber?: number;
  data: Array<T>;
}
export const useImageStore = defineStore('useImageStore', () => {
  const applicationStore = useApplicationStore();
  const allImages = ref<client.Image[]>([]);
  const images = ref<PagedResult<client.Image>>({ data: [], total_count: 0, offset: 0 });
  // const downloads = ref<PagedResult<client.BatchDownload>>({ data: [], total_count: 0, offset: 0 });
  const zips = ref<PagedResult<client.BatchZip>>({ data: [], total_count: 0, offset: 0 });
  const paginationOptions = ref<PaginationOptions>({ pageNumber: 1, pageSize: 12 });

  /**
   * The purpose of this function is to filter images based upon a date range
   * @param viewKey The View key to find the images
   * @param start The start date (The capture date)
   * @param end The end date (The capture date)
   * @returns Returns a flitered set of images
   */
  async function obtainImagesByDateRange(viewId: string, startDate: Date, endDate: Date) {
    endDate.setHours(23, 59, 59, 999);
    paginationOptions.value.pageNumber = 1;
    const res = await client.listImages({
      viewId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      status: client.ImageStatus.COMPLETE,
    });
    allImages.value = res.data;
    filterImagesByPageNumber();
  }
  function filterImagesByPageNumber() {
    const startIndex = (paginationOptions.value.pageNumber - 1) * paginationOptions.value.pageSize;
    const endIndex = startIndex + paginationOptions.value.pageSize;
    images.value = {
      total_count: allImages.value.length,
      offset: startIndex,
      data: allImages.value.slice(startIndex, endIndex),
    };
  }
  /**
   * The purpose of this method is to update the deletion status of the image
   */
  async function deleteImage(imageId: string) {
    await client.deleteImageById({ imageId });
  }
  async function downloadZips(batchDownload: client.BatchDownload) {
    console.log('clicked');
    let tempZips: client.BatchZip[] = [];
    const fullBatchDownLoad = await client.getBatchDownloadById({ batchDownloadId: batchDownload.id });
    console.log(JSON.stringify(fullBatchDownLoad));
    for (let index = 0; index < fullBatchDownLoad.batchZips.length; index++) {
      const zip = await client.getBatchDownloadByIdZipByIndex({
        batchDownloadId: fullBatchDownLoad.id,
        zipIndex: index.toString(),
      });
      if (zip.signedDownloadUrl == undefined) continue;
      tempZips.push(zip);
      // await downloadZipFile(zip.signedDownloadUrl);
    }
    console.log('tempZips: ' + JSON.stringify(tempZips));
    return tempZips;
  }
  async function getDownloads(viewId: string, sortBy: string | undefined = undefined) {
    paginationOptions.value.pageNumber = 1;
    const res = await client.listBatchDownloads({
      organisation: applicationStore.activeOrganisation?.id,
      viewId,
      status: client.BatchDownloadStatus.COMPLETE,
      sort: sortBy,
      page: paginationOptions.value.pageNumber,
      limit: paginationOptions.value.pageSize,
    });

    let allZips: client.BatchZip[] = [];
    for (let index = 0; index < res.data.length; index++) {
      const element = res.data[index];
      const currentZips = await downloadZips(element!);
      allZips = allZips.concat(currentZips);
    }
    zips.value.data = allZips;
    zips.value.total_count = allZips.length;
    zips.value.pageSize = paginationOptions.value.pageSize;
    if (paginationOptions.value.pageNumber) zips.value.pageNumber = paginationOptions.value.pageNumber - 1;
    if (paginationOptions.value.pageSize && paginationOptions.value.pageNumber)
      zips.value.offset = paginationOptions.value.pageSize * (paginationOptions.value.pageNumber - 1);
    console.log(JSON.stringify(zips.value));
  }
  return {
    images,
    allImages,
    filterImagesByPageNumber,
    deleteImage,
    obtainImagesByDateRange,
    getDownloads,
    paginationOptions,
    zips,
  };
});
