<script setup lang="ts">
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

import AuthenticationForm from '@components/account_forms/AuthenticationForm.vue';
import EmailForm from '@components/account_forms/EmailForm.vue';
import UserForm from '@components/account_forms/UserForm.vue';
import AccountLayout from '@layouts/AccountLayout.vue';

/**
 * The vue router
 */
const router = useRouter();
const applicationStore = useApplicationStore();

/**
 * We create a set of wizard states to minimalize the registration process
 */
enum WizardStates {
  EnterUserInformation = 1,
  EnterAndConfirmEmail = 2,
  EnterAuthInformation = 3,
  EnterCompletion = 4,
}

/**
 * The current state of the wizard
 */
const state = ref(WizardStates.EnterUserInformation);

const user = ref({
  forename: '',
  surname: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
}) as Ref<Required<client.CreateUserRequest> & { confirmEmail: string; confirmPassword: string }>;

async function onNextState(): Promise<void> {
  if (state.value < WizardStates.EnterCompletion - 1) {
    state.value = state.value + 1;
    return;
  }

  // If they have hit the final stages of the wizard we want
  // to create the user and move to the dashboard
  try {
    await client.createUser({
      requestBody: {
        ...user.value,
        invitingUser: false,
      },
    });
  } catch (error) {
    applicationStore.publishErrorNotification({
      text: 'Unexpected error occurred when registering user.',
    });
    console.error(error);
    return;
  }
  applicationStore.publishSuccessNotification({
    text: 'Successfully registered user.',
    autoCloseMs: 3000,
  });
  router.push({ name: PageNames.Login });
}

/**
 * This function is used to toggle back to a different wizard state
 */
function onBack(): void {
  if (state.value > WizardStates.EnterUserInformation) {
    state.value = state.value - 1;
  }
}
</script>

<template>
  <AccountLayout>
    <div class="account-form">
      <ul class="steps">
        <li :class="state > 0 ? 'active' : ''">
          Name
        </li>
        <li :class="state > 1 ? 'active' : ''">
          Email
        </li>
        <li :class="state > 2 ? 'active' : ''">
          Password
        </li>
      </ul>
      <template v-if="state === WizardStates.EnterUserInformation">
        <UserForm v-model:forename="user.forename"
                  v-model:surname="user.surname"
                  @on-success="onNextState" />
      </template>
      <template v-else-if="state === WizardStates.EnterAndConfirmEmail">
        <EmailForm v-model:email="user.email"
                   v-model:confirm-email="user.confirmEmail"
                   @on-success="onNextState"
                   @on-back="onBack" />
      </template>
      <template v-else-if="state === WizardStates.EnterAuthInformation">
        <AuthenticationForm v-model:password="user.password"
                            v-model:confirm-password="user.confirmPassword"
                            @on-success="onNextState"
                            @on-back="onBack" />
      </template>
    </div>
    <p class="account-link-container">
      <router-link to="/login"
                   class="account-link">
        Have an account? Login
      </router-link>
    </p>
  </AccountLayout>
</template>
