<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useImageStore } from '@stores/image';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ContainerCard from '@components/cards/ContainerCard.vue';
import BatchDownloadCard from '@components/cards/galleryCard/BatchDownloadCard.vue';
import Directory from '@components/directory/Directory.vue';
import BatchImageDirectoryPagination from '@components/gallery/BatchImageDirectoryPagination.vue';
import VideoDirectoryFilter from '@components/video/VideoDirectoryFilter.vue';
import EmptyState from '@layouts/EmptyState.vue';

const imageStore = useImageStore();

const { zips } = storeToRefs(imageStore);

const route = useRoute();

const selectedFilters = ref<{ client: string | undefined }>({
  client: undefined,
});
const viewId = ref<string>();
const sortBy = ref<string | undefined>();
const loading = ref<boolean>(true);

watch(
  () => route.params['id'],
  async (viewId) => {
    loading.value = true;
    await imageStore.getDownloads(viewId as string, sortBy.value);
    loading.value = false;
  }
);
async function updateSort(newSortBy: string): Promise<void> {
  if (viewId.value == undefined) return;
  sortBy.value = newSortBy;
  loading.value = true;
  await imageStore.getDownloads(viewId.value, newSortBy);
  loading.value = false;
}
onMounted(async () => {
  viewId.value = route.params['id'] as string | undefined;
  if (viewId.value == undefined) return;

  loading.value = true;
  await imageStore.getDownloads(viewId.value);
  loading.value = false;
});
</script>

<template>
  <ContainerCard>
    <div>
      <VideoDirectoryFilter @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                            @sort-view-by="(value: string) => updateSort(value)" />

      <Directory :object-collection-reference="zips.data"
                 :loading="loading">
        <template #default="scope">
          <BatchDownloadCard :resource="scope.resource" />
        </template>
        <template #table-empty>
          <EmptyState heading-text="No downloads found"
                      :icon-name="IconName.PhotoIcon"
                      :icon-style="IconStyle.Outline" />
        </template>
      </Directory>
      <BatchImageDirectoryPagination v-if="viewId"
                                     :view-id="viewId" />
    </div>
  </ContainerCard>
</template>
