<script lang="ts" setup>
import { onMounted, ref } from 'vue';

import * as client from '@gabrielcam/api-client';

import { useViewStore } from '@stores/view';
import { ProgressBarVariant } from '@viewModels/enums';

import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ProgressBar from '@components/ProgressBar.vue';

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});
const isLoading = ref<boolean>(true);
let percent = ref<number>(0);
let charging = ref<boolean>(false);
let powerTypeMessage = ref<string>();
const viewStore = useViewStore();

const MAINS_MAX_VOLTAGE = 25;
const MAINS_MIN_VOLTAGE = 24;
const SOLAR_MAX_VOLTAGE = 24;
const SOLAR_MIN_VOLTAGE = 13;
const MAINS_MAX_VOLTAGE_V2 = 12.3;
const MAINS_MIN_VOLTAGE_V2 = 12.1;
const BATTERY_TOLERANCE = 0.25;
const MAX_VOLTAGE = 8.2;
const MIN_VOLTAGE = 5.5;

interface BatteryInfo {
  percent: number;
  type: string;
  state: number;
  status: number;
  charging: boolean;
}

enum BatteryType {
  Mains = 'MAINS',
  Solar = 'SOLAR',
  Battery = 'BATTERY',
}

const defaultBatteryInfo: BatteryInfo = { percent: 0, type: 'unknown', state: -1, status: -1, charging: false };
const batteryInfo = ref<BatteryInfo>(defaultBatteryInfo);

onMounted(async () => {
  isLoading.value = true;
  try {
    await updateData();
  } catch (error) {
    console.error(error);
  }
  isLoading.value = false;
});

async function GetBatteryInfo(): Promise<void> {
  const currentView = await viewStore.getViewById(props.viewId);
  if (currentView?.camera == undefined) return;
  const stats = await client.getCameraByIdStatLatest({ cameraId: currentView.camera });
  // const busVoltageResponse = await wpClient.MeasurementsService.getMeasurementMeasurementsGet(uuidv4(), "-recordedAtUtc", 1, "INA219_BUSVOLTAGE");
  // const inputVoltageResponse = await wpClient.MeasurementsService.getMeasurementMeasurementsGet(uuidv4(), "-recordedAtUtc", 1, "LT4015_INPUTVOLTAGE");
  // const chargeStateResponse = await wpClient.MeasurementsService.getMeasurementMeasurementsGet(uuidv4(), "-recordedAtUtc", 1, "LT4015_CHARGERSTATE");
  // const chargeStatusResponse = await wpClient.MeasurementsService.getMeasurementMeasurementsGet(uuidv4(), "-recordedAtUtc", 1, "LT4015_CHARGERSTATUS");

  const busVoltage = ((stats.ina219BusVoltage ?? 0) >> 1) * 0.001;
  const inputVoltage = (stats.lt4015InputVoltage ?? 0) * 0.001 * 1.648;
  const chargeState = stats.lt4015ChargerState ?? -1;
  const chargeStatus = stats.lt4015ChargerStatus ?? -1;

  batteryInfo.value.state = chargeState;
  batteryInfo.value.status = chargeStatus;

  if (batteryInfo.value.state > 1 && batteryInfo.value.status > 0) batteryInfo.value.charging = true;

  if (inputVoltage >= MAINS_MIN_VOLTAGE && inputVoltage <= MAINS_MAX_VOLTAGE)
    batteryInfo.value.type = BatteryType.Mains;
  else if (inputVoltage >= MAINS_MIN_VOLTAGE_V2 && inputVoltage <= MAINS_MAX_VOLTAGE_V2)
    batteryInfo.value.type = BatteryType.Mains;
  else if (inputVoltage > SOLAR_MIN_VOLTAGE && inputVoltage < SOLAR_MAX_VOLTAGE)
    batteryInfo.value.type = BatteryType.Solar;
  else if (inputVoltage < SOLAR_MIN_VOLTAGE) batteryInfo.value.type = BatteryType.Battery;

  batteryInfo.value.percent = Math.round(((busVoltage - MIN_VOLTAGE) * 100) / (MAX_VOLTAGE - MIN_VOLTAGE));

  if (busVoltage <= MIN_VOLTAGE) batteryInfo.value.percent = 0;
  if (busVoltage >= MAX_VOLTAGE - BATTERY_TOLERANCE && batteryInfo.value.percent == 99) batteryInfo.value.percent = 100;
  if (batteryInfo.value.percent > 100) batteryInfo.value.percent = 100;
}

async function updateData(): Promise<void> {
  await GetBatteryInfo();
  percent.value = batteryInfo.value.percent;
  if (batteryInfo.value.state > 1 && batteryInfo.value.status > 0) charging.value = true;

  if (batteryInfo.value.type === BatteryType.Mains) powerTypeMessage.value = 'On AC power';
  else if (batteryInfo.value.type === BatteryType.Solar) powerTypeMessage.value = 'On Solar power';
  else if (batteryInfo.value.type === BatteryType.Battery) powerTypeMessage.value = 'On Battery power';
  else powerTypeMessage.value = 'Unknown power source';
}
</script>

<template>
  <Loading v-if="isLoading"
           class="d-flex align-center justify-center w-100" />

  <div v-else
       class="d-flex flex-column w-100">
    <div class="d-flex align-center mb-20">
      <Heading level="3">
        Battery
      </Heading>
    </div>
    <Heading :has-bottom-margin="true"
             level="4">
      {{ percent }}% <span v-if="charging">Charging</span>
    </Heading>

    <ProgressBar :has-text="true"
                 :percent="percent"
                 :has-bottom-margin="true"
                 :variant="ProgressBarVariant.Success" />
    <p>{{ powerTypeMessage }}</p>
  </div>
</template>
