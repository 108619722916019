<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';

import { storeToRefs } from 'pinia';

import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';
import { BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import ViewCard from '@components/cards/shared/ViewCard.vue';
import Directory from '@components/directory/Directory.vue';
import SubHeader from '@components/SubHeader.vue';
import ViewDirectoryFilter from '@components/view/ViewDirectoryFilter.vue';
import EmptyState from '@layouts/EmptyState.vue';

const applicationStore = useApplicationStore();
const viewStore = useViewStore();

const { viewCollectionRef } = storeToRefs(viewStore);
const loading = ref<boolean>(true);

const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const sortBy = ref<string>('-lastCaptured');
const searchBy = ref<string | undefined>();

const createView = applicationStore.canUser(Entitlements.CREATE_VIEW, applicationStore.activeOrganisation!);

const breadcrumbs: BreadCrumbItem[] = [{ title: BreadcrumbTitles.AllViews, active: true }];

async function getViewsList(search?: string, sortBy?: string): Promise<void> {
  loading.value = true;
  await viewStore.obtainViewList(search, sortBy);

  viewCollectionRef.value.data = viewCollectionRef.value.data.filter((view) => {
    return selectedFilters.value.client ? view.client === selectedFilters.value.client : true;
  });
  loading.value = false;
}

async function pageSizeChanged(): Promise<void> {
  viewStore.paginationOptions.pageNumber = 1;
  await getViewsList(searchBy.value, sortBy.value);
}

watch(selectedFilters, () => getViewsList(searchBy.value, sortBy.value), { deep: true });

async function updateSort(searchValue: string | undefined, newSortBy: string): Promise<void> {
  sortBy.value = newSortBy;
  searchBy.value = searchValue;
  await getViewsList(searchValue, newSortBy);
}

onMounted(async () => {
  viewStore.enablePagination(true);
  await getViewsList(searchBy.value, sortBy.value);
});

async function nextPage(): Promise<void> {
  viewStore.paginationOptions.pageNumber++;
  await getViewsList(searchBy.value, sortBy.value);
}

async function previousPage(): Promise<void> {
  viewStore.paginationOptions.pageNumber--;
  if (viewStore.paginationOptions.pageNumber < 0) viewStore.paginationOptions.pageNumber = 0;
  await getViewsList(searchBy.value, sortBy.value);
}
</script>

<template>
  <SubHeader heading="Views"
             level="2">
    <template #buttons>
      <ButtonComponent v-if="createView"
                       :is-block-btn="true"
                       :to="{ name: PageNames.ViewNew }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusCircleIcon"
                       :icon-style="IconStyle.Solid">
        Add View
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <ViewDirectoryFilter :default-sort-by="sortBy"
                         :is-sticky="false"
                         @search="updateSort"
                         @select-client="(clientId: string) => (selectedFilters.client = clientId)" />

    <Directory :loading="loading"
               :object-collection-reference="viewCollectionRef.data">
      <!-- We apply the type of card we want to add -->
      <template #default="scope">
        <!-- @vue-ignore -->
        <ViewCard :resource="scope.resource" />
      </template>

      <template #table-empty>
        <EmptyState heading-text="No views found"
                    strap-line="Get started by assigning a camera to a view"
                    :button-variant="ButtonVariant.Dark"
                    button-text="New View"
                    :icon-name="IconName.PhotoIcon"
                    :icon-style="IconStyle.Outline"
                    :to="{ name: PageNames.ViewNew }" />
      </template>
    </Directory>

    <div class="pagination">
      <div class="pagination-links">
        <div class="icon-group">
          <ButtonComponent v-if="viewStore.paginationOptions.pageNumber > 1"
                           :icon-style="IconStyle.Outline"
                           :icon-position="IconPosition.Left"
                           :icon-name="IconName.ChevronLeftIcon"
                           :is-outline-btn="true"
                           :is-icon-btn="true"
                           :variant="ButtonVariant.Dark"
                           :disabled="viewStore.paginationOptions.pageNumber <= 1"
                           @click="previousPage">
            Previous
          </ButtonComponent>
          <ButtonComponent v-if="viewCollectionRef.offset + viewStore.paginationOptions.pageSize < viewCollectionRef.total_count"
                           :icon-style="IconStyle.Outline"
                           :icon-position="IconPosition.Right"
                           :icon-name="IconName.ChevronRightIcon"
                           :is-outline-btn="true"
                           :is-icon-btn="true"
                           :variant="ButtonVariant.Dark"
                           :disabled="viewCollectionRef.offset + viewStore.paginationOptions.pageSize >= viewCollectionRef.total_count"
                           @click="nextPage">
            Next
          </ButtonComponent>
        </div>
      </div>

      <div class="pagination-info">
        <p>
          {{ viewCollectionRef.offset + 1 }} to
          {{
            viewCollectionRef.offset + viewStore.paginationOptions.pageSize > viewCollectionRef.total_count
              ? viewCollectionRef.total_count
              : viewCollectionRef.offset + viewStore.paginationOptions.pageSize
          }}
          of {{ viewCollectionRef.total_count }} results
        </p>
      </div>

      <div class="pagination-options">
        <v-select v-model="viewStore.paginationOptions.pageSize"
                  :clearable="false"
                  :options="[
                    { value: 12, text: '12 per page' },
                    { value: 24, text: '24 per page' },
                    { value: 36, text: '36 per page' },
                    { value: 48, text: '48 per page' },
                    { value: 60, text: '60 per page' },
                  ]"
                  :reduce="(value: any) => value.value"
                  :searchable="false"
                  label="text"
                  @option:selected="pageSizeChanged" />
      </div>
    </div>
  </ContainerCard>
</template>
