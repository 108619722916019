export class ScheduleViewModel {
  month: string = '';
  monday: boolean = true;
  tuesday: boolean = true;
  wednesday: boolean = true;
  thursday: boolean = true;
  friday: boolean = true;
  saturday: boolean = true;
  sunday: boolean = true;

  startHour: string = '09:00';
  endHour: string = '17:00';
  captureRate: number = 5;

  rapidCaptureEnabled: boolean = false;
  rapidStartHour: string = '09:00';
  rapidEndHour: string = '17:00';
  rapidCaptureRate: number = 5;

  getNextEnabledDay(): string | null {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date().getDay();

    for (let i = 0; i < dayNames.length; i++) {
      const nextDayIndex = (today + i) % 7;
      const nextDayName: string = dayNames[nextDayIndex] ?? '';

      if (this[nextDayName.toLowerCase() as keyof ScheduleViewModel] === true) {
        return nextDayName;
      }
    }
    return null; // No enabled days found in the week
  }
}
