<script setup lang="ts">
// Define the props
const props = defineProps<{
  variant?: 'solid' | 'dashed';
  theme?: 'light' | 'dark';
  height?: number;
}>();

// Default values
const variantClass = props.variant ?? 'solid';
const themeClass = props.theme ?? 'light';
const heightValue = props.height ?? 1;
</script>

<template>
  <hr class="hr"
      :class="['hr--' + themeClass, 'hr--' + variantClass]"
      :style="variantClass !== 'dashed' ? { borderTopWidth: heightValue + 'px' } : {}">
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.hr {
  border: none;
  width: 100%;
  border-radius: 5px;
  margin-bottom: $margin-bottom;

  &--light {
    border-top-color: $neutral-300;
  }

  &--dark {
    border-top-color: $neutral-800;
  }

  &--solid {
    border-top-style: solid;
  }

  &--dashed {
    border-top-style: dashed;
  }
}
</style>
