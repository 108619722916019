<script lang="ts" setup>
import { onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { useVideoStore, Video } from '@stores/video';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import VideoCard from '@components/cards/videoCard/VideoCard.vue';
import Directory from '@components/directory/Directory.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import VideoDirectoryFilter from '@components/video/VideoDirectoryFilter.vue';
import VideoDirectoryPagination from '@components/video/VideoDirectoryPagination.vue';
import VideoPlayer from '@components/video/VideoPlayer.vue';

const videoStore = useVideoStore();
const applicationStore = useApplicationStore();

const route = useRoute();
const { videos } = storeToRefs(videoStore);
const loading = ref<boolean>(true);

const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const videoPlayerVisible = ref(false);
const videoPlayerUrl = ref<string>('');
const viewId = ref<string>();
const sortBy = ref<string | undefined>();

watch(
  () => route.params['viewId'],
  async (viewId) => {
    await videoStore.getVideos(viewId as string, sortBy.value);
  }
);

async function updateSort(newSortBy: string): Promise<void> {
  sortBy.value = newSortBy;
  await videoStore.getVideos(viewId.value, newSortBy);
}

onMounted(async () => {
  loading.value = true;
  viewId.value = route.params['viewId'] as string | undefined;

  videoStore.setPagination();
  await videoStore.getVideos(viewId.value);
  loading.value = false;
});

function closeVideoPlayerModal(): void {
  videoPlayerVisible.value = false;
}

function openVideoPlayer(video: Video): void {
  videoPlayerVisible.value = true;
  videoPlayerUrl.value = video.url ?? '';
}

const showNew = applicationStore.canUser(Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.AllViews },
  { title: BreadcrumbTitles.Videos, active: true },
];
</script>

<template>
  <SubHeader heading="Videos"
             level="2">
    <template v-if="showNew"
              #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.VideoNew, params: { viewId: viewId } }"
                       :variant="ButtonVariant.Dark"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PlusCircleIcon"
                       :icon-style="IconStyle.Solid">
        Add Video
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <div>
          <VideoDirectoryFilter @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                                @sort-view-by="(value: string) => updateSort(value)" />

          <Directory :loading="loading"
                     :object-collection-reference="videos.data">
            <!-- We apply the type of card we want to add -->
            <template #default="scope">
              <!-- @vue-ignore -->
              <VideoCard :resource="scope.resource"
                         @on-click="openVideoPlayer(scope.resource)" />
            </template>
          </Directory>

          <VideoDirectoryPagination :view-id="viewId"
                                    @on-loading="loading = true"
                                    @on-loaded="loading = false" />
        </div>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Video Player Modal -->
  <ModalComponent :visible="videoPlayerVisible"
                  heading-title="Watch Video"
                  @on-close="closeVideoPlayerModal">
    <template #modal-content>
      <VideoPlayer :video-src="videoPlayerUrl" />
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeVideoPlayerModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
