<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { PageNames } from '@viewModels/enums';

import SubHeader from '@components/SubHeader.vue';

const route = useRoute();
const userId = route.params['id'] as string;
const pageName = ref<string>('');
const user = ref<client.User>();

function updateSubHeader(): void {
  if (route.name === PageNames.UserEdit) pageName.value = 'Settings';
  else if (route.name === PageNames.UserRoles) pageName.value = 'User Roles & Permissions';
  else pageName.value = '';
}

onBeforeMount(async () => {
  user.value = await client.getUserById({ userId });
  updateSubHeader();
});
watch(
  () => route.name,
  () => {
    updateSubHeader();
  }
);
</script>

<template>
  <SubHeader :heading="pageName"
             level="2" />

  <router-view />
</template>
