<script lang="ts" setup>
interface CardContentProps {
  thumbnail?: string | '';
  thumbnailAlt?: string;
  title: string;
  overlay?: string | null;
  overlay2?: string | null;
  status?: string | null;
  statusColour?: string;
  link?: string | '';
}

const props = withDefaults(defineProps<CardContentProps>(), {
  thumbnail: '',
  thumbnailAlt: 'Image Alt Text',
  statusColour: 'status-green',
  overlay: null,
  overlay2: null,
  status: null,
  link: '',
});

const emit = defineEmits<{
  (e: 'onClick'): void;
}>();
</script>

<template>
  <div class="collection-item-image">
    <a v-if="!link"
       class="collection-item-image-link"
       href="javascript:"
       @click="emit('onClick')">
      <img v-if="props.thumbnail"
           :src="props.thumbnail"
           alt=""
           aria-hidden="true">
      <img v-else
           alt=""
           aria-hidden="true"
           src="/src/assets/background-light.png">
    </a>
    <a v-else
       :href="link"
       class="collection-item-image-link">
      <img :src="props.thumbnail"
           alt=""
           aria-hidden="true">
    </a>
    <div v-if="props.status"
         :class="props.statusColour"
         class="collection-item-status">
      <p>{{ props.status }}</p>
    </div>
    <div class="collection-item-overlay">
      <p>{{ props.overlay }}</p>
    </div>
    <div class="collection-item-overlay2">
      <p>{{ props.overlay2 }}</p>
    </div>
  </div>
  <div class="collection-item-title">
    <p>{{ props.title }}</p>
  </div>
</template>
