<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { AlertVariant } from '@viewModels/enums';

import AlertBanner from '@components/AlertBanner.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import EnergyChartPanels from '@components/view_dashboard/EnergyChartPanels.vue';

// Route and Camera Information
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();

// View Information
const viewId = ref<string | undefined>();
const detailedView = ref<client.View | undefined>();
const noViewFound = ref<boolean>(false);
const applicationStore = useApplicationStore();

// Loading States
const isLoadingViews = ref<boolean>(false);
const isLoadingViewDetails = ref<boolean>(false);
const isLoadingCamera = ref<boolean>(true);

// Fetches the associated view by cameraId
async function getView(): Promise<void> {
  isLoadingViews.value = true;

  // Fetch all views associated with the organisation
  const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });

  // Return early if no views are found
  if (!viewsResult.data || viewsResult.data.length === 0) {
    console.warn('No views found.');
    noViewFound.value = true;
    isLoadingViews.value = false;
    return;
  }

  // Find the view matching the cameraId
  const matchedDetailedView = viewsResult.data.find((view) => view.camera === cameraId);

  if (matchedDetailedView) {
    viewId.value = matchedDetailedView.id;
    detailedView.value = matchedDetailedView;
  } else {
    console.warn('No view found for this camera.');
    noViewFound.value = true;
  }

  isLoadingViews.value = false;
}

onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    await getView();
  } catch (error) {
    console.error('Error fetching camera or view data:', error);
  } finally {
    isLoadingCamera.value = false;
  }
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoadingViews || isLoadingViewDetails || isLoadingCamera" />

    <template v-else>
      <AlertBanner v-if="noViewFound"
                   :variant="AlertVariant.Warning">
        <template #mainContent>
          No view found for {{ currentCamera?.serialNumber }} camera.
        </template>
      </AlertBanner>

      <template v-if="!noViewFound && detailedView">
        <Heading v-if="currentCamera"
                 level="3"
                 :has-bottom-margin="true">
          Power
        </Heading>

        <div class="d-flex flex-column gap-20 mb-20">
          <EnergyChartPanels v-if="viewId"
                             :view-id="viewId" />
        </div>
      </template>
    </template>
  </ContainerCard>
</template>

<style lang="scss" scoped></style>
