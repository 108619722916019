<script lang="ts" setup>
import { computed, ref } from 'vue';

import { getIconComponent } from '@utils/heroIconsMap';

import { AlertVariant, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';

// AlertBanner Interface
interface AlertBannerProps {
  iconName?: IconName;
  iconStyle?: IconStyle;
  variant?: AlertVariant;
  hasShadow?: boolean;
  hasCloseButton?: boolean;
}

// Use the interface directly with defineProps
const props = defineProps<AlertBannerProps>();

// Define Defaults
const variant = computed(() => props.variant || 'info');
const hasShadow = computed(() => props.hasShadow || false);

// Emit close event
const emit = defineEmits(['close']);

// Show the notification bar
const showNotificationBar = ref(true);

// Method to emit the close event
const emitClose = (): void => {
  emit('close');
  showNotificationBar.value = false;
};

// Expose a method to programmatically close the notification
const closeNotificationBar = (): void => {
  showNotificationBar.value = false;
};

defineExpose({
  closeNotificationBar,
});
</script>

<template>
  <transition name="fade">
    <div v-if="showNotificationBar"
         :aria-hidden="!showNotificationBar"
         class="notification-bar">
      <div :class="['notification-bar--holder', variant, { 'notification-bar--shadow': hasShadow }]">
        <component :is="getIconComponent(iconName, iconStyle)"
                   v-if="iconName"
                   aria-hidden="true"
                   class="notification-bar--icon" />

        <span :class="['notification-bar--content', variant]">
          <slot name="mainContent" />
        </span>

        <ButtonComponent v-if="hasCloseButton"
                         :icon-name="IconName.XMarkIcon"
                         :icon-style="IconStyle.Outline"
                         :is-icon-btn="true"
                         :is-outline-btn="true"
                         aria-label="Close Banner"
                         class="notification-bar--close"
                         :variant="ButtonVariant.Link"
                         @click="emitClose" />
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.notification-bar {
  position: relative;

  &--holder {
    align-items: center;
    border-radius: 10px;
    display: flex;
    gap: 15px;
    margin-bottom: $margin-bottom;
    padding: 15px;
    pointer-events: all;
    color: $neutral-50;
    border: 1px solid transparent;

    &.primary {
      background-color: $purple-800;
      border: 1px solid $purple-300;
      border-left: 10px solid $purple-600;
    }

    &.secondary {
      background-color: $gold-600;
      border: 1px solid $gold-900;
      border-left: 10px solid $gold-900;
      color: $gold-900;
    }

    &.success {
      background-color: $green-300;
      border: 1px solid $green-900;
      border-left: 10px solid $green-900;
      color: $green-900;
    }

    &.danger {
      background-color: $red-200;
      border: 1px solid $red-900;
      border-left: 10px solid $red-900;
      color: $red-900;
    }

    &.info {
      background-color: $blue-100;
      border: 1px solid $blue-900;
      border-left: 10px solid $blue-900;
      color: $blue-900;
    }

    &.warning {
      background-color: $orange-100;
      border: 1px solid $orange-900;
      border-left: 10px solid $orange-900;
      color: $orange-900;
    }

    &.light {
      background-color: $neutral-200;
      border: 1px solid $neutral-600;
      border-left: 10px solid $neutral-600;
      color: $neutral-600;
    }

    &.dark {
      background-color: $neutral-700;
      border-left: 10px solid $neutral-600;
    }
  }

  &--shadow {
    box-shadow: $box-shadow;
  }

  &--icon {
    color: inherit;
    height: 30px;
    min-width: 30px;
  }

  &--content {
    color: inherit;
  }

  &--close {
    color: inherit;
    margin-left: auto;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      user-select: none;
      color: inherit;
    }
  }
}

.fade-enter-active {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-leave-active {
  animation: fadeOut 0.3s ease-out forwards;
}
</style>
