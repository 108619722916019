// Camera Statuses
export enum CameraStatus {
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED',
}

// View Statuses
export enum ViewStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

// User Statuses
export enum UserStatus {
  Active = 'ACTIVE',
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
}

// Define the Router Page Names for Views
export enum PageNames {
  Admin = 'admin',
  Account = 'account',
  Camera = 'camera',
  Cameras = 'cameras',
  CameraAPI = 'camera-api',
  CameraDevice = 'camera-device',
  CameraNetwork = 'camera-network',
  CameraNew = 'camera-new',
  CameraLogs = 'camera-logs',
  CameraPower = 'camera-power',
  CameraProvision = 'camera-provision',
  CameraRegister = 'camera-register',
  CameraSchedule = 'camera-schedule',
  CameraSettings = 'camera-settings',
  CameraStatus = 'camera-status',
  ChangePassword = 'change-password',
  Clients = 'clients',
  ClientCreate = 'client-create',
  ClientProjectsCreate = 'client-projects-create',
  ClientSettings = 'client-settings',
  Events = 'events',
  Forgot = 'forgot',
  Home = 'home',
  Login = 'login',
  Logout = 'logout',
  NotFound = 'not-found',
  Organisation = 'organisation',
  Organisations = 'organisations',
  OrganisationSettings = 'organisation-settings',
  Project = 'project',
  ProjectEdit = 'project-edit',
  ProjectCreate = 'project-create',
  Projects = 'projects',
  Register = 'register',
  Subscriptions = 'subscriptions',
  SubscriptionNotifications = 'subscription-notifications',
  SubscriptionViews = 'subscription-views',
  SubscriptionProjects = 'subscription-projects',
  SubscriptionClients = 'subscription-clients',
  SubscriptionLicenses = 'subscription-licenses',
  User = 'user',
  Users = 'users',
  UserEdit = 'user-edit',
  UserInvitation = 'user-invitation',
  UserRoles = 'user-roles',
  VideoNew = 'video-new',
  Videos = 'videos',
  View = 'view',
  ViewDownloads = 'view-downloads',
  ViewGallery = 'view-gallery',
  ViewInformation = 'view-information',
  ViewMap = 'view-map',
  ViewNew = 'view-new',
  ViewVideos = 'view-videos',
  ViewViewer = 'view-viewer',
  Views = 'views',
  Welcome = 'welcome',
}

// Define the Breadcrumbs
export enum BreadcrumbTitles {
  API = 'API',
  AllVideos = 'All Videos',
  AllViews = 'Views',
  Cameras = 'Cameras',
  CreateClient = 'Create Client',
  Clients = 'Clients',
  CreateProject = 'Create Project',
  CreateVideo = 'Create Video',
  CreateView = 'Create View',
  Device = 'Device',
  Home = 'Home',
  Information = 'Information',
  Network = 'Network',
  Power = 'Power',
  Projects = 'Projects',
  Schedule = 'Schedule',
  Status = 'Status',
  SubscriptionViews = 'Subscription Views',
  UpdateClient = 'Update Client',
  UpdateProject = 'Update Project',
  Viewer = 'Viewer',
  Videos = 'Videos',
}

// Define the Breadcrumb Paths
export enum BreadcrumbPaths {
  AllVideos = '/videos',
  AllViews = '/views',
  Cameras = '/cameras',
  Clients = '/subscriptions/clients',
  Home = '/',
  Projects = '/subscriptions/projects',
  Users = '/users',
  Videos = '/videos',
}

// Define the Main Menu
export enum MainMenuTitles {
  Admin = 'Admin',
  AllViews = 'Views',
  Cameras = 'Cameras',
  Settings = 'Settings',
  Status = 'Status',
  Subscriptions = 'Subscriptions',
  Videos = 'Videos',
}

// Admin Menu
export enum AdminMenuTitles {
  Organisations = 'Organisations',
  Users = 'Users',
  EventStreams = 'Event Streams',
}

// Subscriptions Menu
export enum SubscriptionMenuTitles {
  Views = 'Views',
  Projects = 'Projects',
  Clients = 'Clients',
}

// MultiStep Progress Steps
export enum StepStatus {
  Default = 'default',
  Pending = 'pending',
  Completed = 'completed',
  Error = 'error',
}

// MultiStep Fetch Status
export enum FetchStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Success = 'success',
  Error = 'error',
}

// Alert Banner Variants
export enum AlertVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
}

// Button Variants
export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
  Transparent = 'transparent',
}

export enum ButtonType {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset',
}

export enum ButtonSize {
  Small = 'small',
  Large = 'large',
}

export enum ButtonStyle {
    Rectangle = 'rectangle',
    Pill = 'pill',
    Rounded = 'rounded',
}

// Badge Theme Variants
export enum BadgeVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
  Link = 'link',
  Black = 'black',
  Transparent = 'transparent',
  Outline = 'outline',
}

// Card Theme Variants
export enum CardVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Light = 'light',
  Dark = 'dark',
  TransparentDark = 'transparent-dark',
  TransparentLight = 'transparent-light',
}

// Progress Bar Variants
export enum ProgressBarVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark',
}

export enum ProgressBarSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}
