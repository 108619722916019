<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbTitles, ButtonVariant } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';

const applicationStore = useApplicationStore();
const { activeUser } = storeToRefs(applicationStore);

const breadcrumbs: BreadCrumbItem[] = [{ title: BreadcrumbTitles.AllViews, active: true }];
</script>

<template>
  <SubHeader heading="Account"
             level="2">
    <template #buttons>
      <ButtonComponent :is-block-btn="true"
                       to="/logout"
                       :variant="ButtonVariant.Dark">
        Logout
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       to="/ChangePassword"
                       :variant="ButtonVariant.Dark">
        Change Password
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <form @submit="() => {}">
      <div class="field-group">
        <div class="field-group-info">
          <h3>User Information</h3>
          <p>Some info about the user.</p>
        </div>
        <div v-if="activeUser"
             class="fields">
          <div class="row-half">
            <div class="field">
              <label id="input-vname"
                     for="displayName">Display Name</label>
              <input id="displayName"
                     v-model="activeUser.displayName"
                     disabled
                     type="text">
            </div>
            <div class="field">
              <label id="input-vname"
                     for="email">Email</label>
              <input id="email"
                     v-model="activeUser.email"
                     disabled
                     type="text">
            </div>
          </div>
          <div class="row-half">
            <div class="field">
              <label id="input-vname"
                     for="status">Status</label>
              <input id="status"
                     v-model="activeUser.status"
                     disabled
                     type="text">
            </div>
          </div>
        </div>
      </div>
    </form>
  </ContainerCard>
</template>
