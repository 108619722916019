import { ref } from 'vue';

import { defineStore } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from './application';

export interface PaginationOptions {
  pageNumber: number;
  pageSize: number;
}
export interface PagedResult<T> {
  total_count: number;
  offset: number;
  pageSize?: number;
  pageNumber?: number;
  data: Array<T>;
}
export interface Video extends client.Sequence {
  name: string;
  clientId?: string;
  viewName?: string;
  thumbnail?: string;
  length?: number;
  url?: string;
  downloadURL?: string;
}

export const useVideoStore = defineStore('useVideoStore', () => {
  const applicationStore = useApplicationStore();
  const videos = ref<PagedResult<Video>>({ data: [], total_count: 0, offset: 0 });
  const paginationOptions = ref<PaginationOptions>({ pageNumber: 1, pageSize: 12 });
  const videoSelected = ref<Video>();
  const videoSelectedForDeletion = ref<Video>();

  const selectedFilters = ref<{ client: string | undefined; view: string | undefined }>({
    client: undefined,
    view: undefined,
  });

  async function getVideos(
    viewId: string | undefined = undefined,
    sortBy: string | undefined = '-createdAt'
  ): Promise<PagedResult<Video>> {
    const sequencesResult = await client.listSequences({
      organisation: applicationStore.activeOrganisation!.id,
      viewId,
      sort: sortBy,
      page: paginationOptions.value.pageNumber,
      limit: paginationOptions.value.pageSize,
    });
    const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id }); // Either return data in api or filter by view IDs

    let sequencesData = sequencesResult.data;
    videos.value.total_count = sequencesResult.total_count;
    videos.value.pageSize = paginationOptions.value.pageSize;
    if (paginationOptions.value.pageNumber) videos.value.pageNumber = paginationOptions.value.pageNumber - 1;
    if (paginationOptions.value.pageSize && paginationOptions.value.pageNumber)
      videos.value.offset = paginationOptions.value.pageSize * (paginationOptions.value.pageNumber - 1);

    let tempVideos = sequencesData.map((sequence) => {
      const view = viewsResult.data.find((x) => x.id == sequence.view);
      const name = sequence.name ?? view?.name;
      let downloadURL = sequence.downloadURL;
      if (downloadURL) downloadURL = downloadURL + `?filename=${generateDownloadFileName(name)}.mp4`;
      return {
        ...sequence,
        name: name,
        viewName: view?.name,
        length: sequence?.duration ?? 0,
        thumbnail: sequence.thumbnailUrl,
        url: sequence.iframeUrl,
        clientId: view?.client,
        downloadURL: downloadURL,
        //lastSeen: view?.lastSeenUtc !== undefined ? new Date(view?.lastSeenUtc) : undefined,
      } as Video;
    });

    tempVideos = tempVideos.filter((video) => {
      if (selectedFilters.value.client)
        return selectedFilters.value.client ? video.clientId === selectedFilters.value.client : true;
      if (selectedFilters.value.view)
        return selectedFilters.value.view ? video.view === selectedFilters.value.view : true;
      return true;
    });
    videos.value.data = tempVideos;
    return videos.value;
  }
  function setPagination(page: number = 1, pageSize: number = 12) {
    paginationOptions.value.pageNumber = page;
    paginationOptions.value.pageSize = pageSize;
  }

  function generateDownloadFileName(name: string) {
    // really do not like this...
    // Define the regular expression pattern to match characters not in the specified set
    const pattern = /[^a-zA-Z0-9-_]/g;

    // Use the replace method to replace characters with an empty string
    const result = name.replace(pattern, '-');

    return result;
  }

  return {
    videos,
    videoSelected,
    videoSelectedForDeletion,
    paginationOptions,
    selectedFilters,
    setPagination,
    getVideos,
  };
});
