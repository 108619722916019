<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { getAuth, updatePassword } from 'firebase/auth';

import * as client from '@gabrielcam/api-client';

import { getContinueUrl } from '@utils/continueUrl';
import { firebaseApp } from '@utils/firebase';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

import ResetPasswordForm from '@components/account_forms/ResetPasswordForm.vue';
import SetNameForm from '@components/account_forms/SetNameForm.vue';
import AccountLayout from '@layouts/AccountLayout.vue';

enum Step {
  SetPassword = 1,
  SetName = 2,
  Success = 3,
}

const router = useRouter();
const applicationStore = useApplicationStore();

const step = ref(Step.SetPassword);

const resetPassword = async (newPassword: string): Promise<void> => {
  const authProvider = getAuth(firebaseApp);
  await updatePassword(authProvider.currentUser!, newPassword);
  step.value = Step.SetName;
};

const updateUser = async (forename: string, surname: string): Promise<void> => {
  const apiUser = await client.updateUserById({
    userId: applicationStore.activeUser!.id,
    requestBody: {
      forename,
      surname,
      displayName: forename,
      status: client.UserStatus.ACTIVE,
    },
  });
  step.value = Step.Success;
  applicationStore.activeUser = apiUser;

  applicationStore.publishSuccessNotification({
    text: 'Successfully activated user.',
    autoCloseMs: 3000,
  });

  setTimeout(() => {
    let redirectPath = getContinueUrl(router);
    router.push(redirectPath ? { path: redirectPath } : { name: PageNames.Views });
  }, 2000);
};
</script>

<template>
  <AccountLayout>
    <div class="account-form">
      <section v-show="step === Step.SetPassword">
        <ResetPasswordForm :reset-password="resetPassword" />
      </section>
      <section v-show="step === Step.SetName">
        <SetNameForm :update-user="updateUser" />
      </section>
      <section v-show="step === Step.Success">
        <h2>Success!</h2>
        <p>You'll be redirected to your dashboard</p>
      </section>
    </div>
  </AccountLayout>
</template>
