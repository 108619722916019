import { IconName, IconStyle } from '@viewModels/heroIcons';

import * as OutlineIcons from '@heroicons/vue/24/outline';
import * as SolidIcons from '@heroicons/vue/24/solid';

export function getIconComponent(iconName: IconName, style: IconStyle = IconStyle.Solid) {
  let iconComponent;

  if (style === IconStyle.Outline) {
    iconComponent = OutlineIcons[iconName];
  } else if (style === IconStyle.Solid) {
    iconComponent = SolidIcons[iconName];
  }

  if (!iconComponent) {
    console.warn(`Icon component not found for name: ${iconName} in ${style} style`);
    return null;
  }
  return iconComponent;
}
