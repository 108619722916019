<script setup lang="ts">
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';

const props = defineProps<{
  downloadURL: string | undefined;
}>();

const emit = defineEmits<{
  (e: 'onPlay'): void;
  (e: 'delete'): void;
}>();
const applicationStore = useApplicationStore();
const showDelete = applicationStore.canUser(Entitlements.DELETE_SEQUENCE, applicationStore.activeOrganisation!);

const downloadFile = (): void => {
  const url = props.downloadURL;
  if (url) {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    alert('Video not found. Please try again or contact support.');
  }
};
</script>

<template>
  <div class="collection-item-actions">
    <ul>
      <li>
        <button class="action-play"
                data-title="Play"
                title="Play"
                @click="emit('onPlay')">
          Play
        </button>
      </li>
      <li v-if="props.downloadURL">
        <button class="action-download"
                title="Download"
                @click="downloadFile">
          Download
        </button>
      </li>
      <li v-if="showDelete">
        <button class="action-delete"
                title="Delete"
                @click="emit('delete')">
          Delete
        </button>
      </li>
    </ul>
  </div>
</template>
